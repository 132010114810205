import {makeAutoObservable, makeObservable} from "mobx"
import React from "react"

class SliderStore{
    constructor() {
        this.selectedItemIndex = 0
        makeAutoObservable(this)
    }

    changeItem = ({next, slider}) => {
        const left = slider.current.style.left ?
            Number(slider.current.style.left.slice(0, -2)) : 0
        slider.current.style.left = next ? left - slider.current.firstChild.clientWidth + 'px' :
            left + slider.current.firstChild.clientWidth + 'px'
        this.selectedItemIndex = next ? this.selectedItemIndex + 1 : this.selectedItemIndex - 1
    }
}

export default SliderStore