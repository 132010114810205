import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import AdditionalRadialsStyles from '../../styles/AdditionalRadialsStyles'
import { getStyles } from '../../../../utils/utils/commonUtils'
import refreshIcon from '../../../../assets/images/common/refresh.svg'
import optionIcon from '../../../../assets/images/common/threeDotOptions.svg'
import { useState } from 'react'
import TooltipButton from 'src/components/CustomTooltip/TooltipButton'
import TooltipInfoContent from 'src/components/CustomTooltip/TooltipInfoContent'

const PlateTitle = ({ title, updateFunc, tooltipData, propStyles, btnStyles, optionsButtonContent }) => {
    const classes = getStyles(AdditionalRadialsStyles)
    const [disabledButton, setDisabledButton] = useState(false)
    const handleActionOnClick = () => {
        const timeout = window.SAT.config.dashboardsReloadTimeout ? window.SAT.config.dashboardsReloadTimeout : 6000
        updateFunc()
        setDisabledButton(true)
        setTimeout(() => {
            setDisabledButton(false)
        }, timeout)
    }

    return (
        <Box sx={{ ...classes.wrapper__content, ...propStyles }}>
            <Typography variant='h5' sx={classes.title}>
                {title}
            </Typography>
            <Box sx={{ ...classes.buttonsWrapper, ...btnStyles }}>
                {tooltipData && (
                    <TooltipButton
                        content={<TooltipInfoContent title={title} data={tooltipData} />}
                        title={title}
                        titleIcon={'blue'}
                        placement={'left-end'}
                    />
                )}
                {/*<IconButton*/}
                {/*    disabled={disabledButton}*/}
                {/*    onClick={handleActionOnClick}*/}
                {/*    sx={classes.iconButton}*/}
                {/*    disableFocusRipple={true}*/}
                {/*>*/}
                {/*    <img style={{opacity: disabledButton ? 0.5 : 1}} src={refreshIcon} alt="refresh icon" />*/}
                {/*</IconButton>*/}
            </Box>
        </Box>
    )
}

export default PlateTitle
