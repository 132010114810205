import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import {Box, IconButton, Paper, Typography} from "@mui/material"
import {useIntl} from "react-intl"
import RegionsStore from "../../stores/RegionsStore"
import refeshIcon from "../../../../assets/images/common/refresh.svg"
import {getStyles} from "../../../../utils/utils/commonUtils"
import AdditionalRadialsStyles from "../../styles/AdditionalRadialsStyles"
import AdditionalBaseItem from "../common/AdditionalBaseItem"
import PlateTitle from "../common/PlateTitle"
import ComponentProgressBackdrop from "../../../../components/ComponentProgressBackdrop/ComponentProgressBackdrop"

const AdditionalRadialRegionPlate = observer(() => {
    const [store] = useState(() => new RegionsStore())
    const intl = useIntl()
    const classes = getStyles(AdditionalRadialsStyles)

    useEffect(() => {
        getAllData()
    }, [])

    function getAllData() {
        store.getData()
    }

    const graphTooltipData = [
        "Виджет показывает диаграммы  соотношения заявок / установок по отдельным регионам относительно их общего количества.",
        "В центре диаграммы - общее количество  по всем регионам, взятое за 100%.",
        "Сектора диаграммы изображают показатели по отдельным регионам: количество, процент и долю относительно общего количества по всем регионам."
    ]
    const firstWidgetTooltipData = [
        "Диаграмма показывает соотношение заявок по отдельным регионам относительно их общего количества.",
        "В центре диаграммы - общее количество заявок по всем регионам, взятое за 100%.",
        "Сектора диаграммы изображают заявки по отдельным регионам: их количество, процент и долю относительно общего количества заявок по всем регионам."
    ]

    const secondWidgetTooltipData = [
        "Диаграмма показывает соотношение количества установок по отдельным регионам относительно их общего количества.",
        "В центре диаграммы - общее количество установок по всем регионам, взятое за 100%.",
        "Сектора диаграммы изображают установки по отдельным регионам: их количество, процент и долю относительно общего количества установок по всем регионам."
    ]

    return (
        <Paper sx={classes.wrapper}>
            <PlateTitle
                title={intl.formatMessage({
                    id: "Сводная статистика заявок и установок"
                })}
                updateFunc={getAllData}
                tooltipData={graphTooltipData}
            />

            <Box sx={classes.contentWrapper}>
                <AdditionalBaseItem
                    title={intl.formatMessage({id: "Заявки по регионам"})}
                    dataForCanvas={store.store[1].dataForCanvas}
                    data={store.store[1].data}
                    colorsArr={store.store[1].colorsArr}
                    total={store.store[1].totalCount}
                    totalColor={store.store[1].totalColor}
                    maxWidth={"248px"}
                    graphTooltipData={firstWidgetTooltipData}
                    totalText={intl.formatMessage({
                        id: "Всего заявок"
                    })}
                />
                <AdditionalBaseItem
                    title={intl.formatMessage({id: "Установки по регионам"})}
                    dataForCanvas={store.store[2].dataForCanvas}
                    data={store.store[2].data}
                    colorsArr={store.store[2].colorsArr}
                    total={store.store[2].totalCount}
                    totalColor={store.store[2].totalColor}
                    maxWidth={"248px"}
                    graphTooltipData={secondWidgetTooltipData}
                    totalText={intl.formatMessage({
                        id: "Всего установок"
                    })}
                    holeSize={0.5}
                />
            </Box>
            <ComponentProgressBackdrop isOpen={store.isLoading} />
        </Paper>
    )
})

export default AdditionalRadialRegionPlate
