import React, {useEffect, useRef, useState} from "react"
import mobileSliderContainerStyles from "../../styles/mobileSliderContainerStyles"
import {getStyles} from "../../../../utils/utils/commonUtils"
import Box from "@mui/material/Box"
import withWidth, {isWidthUp} from "@mui/material/Hidden/withWidth"
import SliderButtons from "src/views/AdditionalSummary/components/MobileSliderContainer/SliderButtons"
import SliderStore from "src/views/AdditionalSummary/stores/SliderStore"
import {observer} from "mobx-react"

function MobileSliderContainer({children, width}) {
    const classes = getStyles(mobileSliderContainerStyles)
    const slider = useRef()
    const [sliderStore] = useState(() => new SliderStore())
    useEffect(() => {
        return () => (sliderStore.selectedItemIndex = 0)
    }, [width])

    let slides = children.filter((el) => {
        if (el) {
            return el
        }
    })
    const slidesTotal = slides.length

    // десктоп
    if (isWidthUp("lg", width)) {
        return children
    }
    // планшет
    if (isWidthUp("sm", width)) {
        return (
            <>
                <Box sx={{...classes.sliderContainer}}>
                    <Box ref={slider} sx={{...classes.slider}}>
                        {children}
                    </Box>
                </Box>
                <Box sx={classes.sliderBar}>
                    {slides.map((el, i) => (
                        <Box
                            key={"widgetItem" + i}
                            sx={{
                                ...classes.sliderBarItem,
                                ...(i === sliderStore.selectedItemIndex ? classes.sliderBarItemSelected : {})
                            }}
                        />
                    ))}
                </Box>
                <SliderButtons sliderStore={sliderStore} total={slidesTotal} slider={slider} />
            </>
        )
    }

    // мобильный
    return (
        <>
            <Box sx={{...classes.sliderContainer}}>
                <Box ref={slider} sx={{...classes.slider}}>
                    {children}
                </Box>
            </Box>
            <Box sx={classes.sliderBar}>
                {children.map((el, i) => (
                    <Box
                        key={"widgetItem" + i}
                        sx={{
                            ...classes.sliderBarItem,
                            ...(i === sliderStore.selectedItemIndex ? classes.sliderBarItemSelected : {})
                        }}
                    />
                ))}
            </Box>
            <SliderButtons sliderStore={sliderStore} total={children.length} slider={slider} />
        </>
    )
}

export default withWidth()(observer(MobileSliderContainer))
