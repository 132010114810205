import {Box, Typography} from "@mui/material"
import React from "react"
import {getStyles} from "../../../../../utils/utils/commonUtils"
import AdditionalRadialsStyles from "../../../styles/AdditionalRadialsStyles"

const StatisticTableItem = ({item}) => {
    const classes = getStyles(AdditionalRadialsStyles)
    return (
        <Box sx={classes.item__listElement} key={item.name}>
            <Box sx={{display: "flex"}}>
                {
                    item.color ?
                        <Box
                            sx={{
                                minWidth: "16px",
                                height: "16px",
                                background: item.color,
                                marginRight: "8px",
                            }}
                        ></Box> : null
                }
                <Typography type="body1">
                    {item.name}
                </Typography>
            </Box>
            <Box sx={{display: "flex", gap: "12px",justifyContent: "flex-end"}}>
                <Box >
                    <Typography align="right">{item.count}</Typography>
                </Box>

                <Box width={"40px"}>
                    <Typography sx={{minWidth: "30px"}} align="right">
                        {`${item.percentOfTotal}%`}
                    </Typography>
                </Box>

            </Box>
        </Box>
    )
}

export default StatisticTableItem
