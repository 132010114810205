import React, {useRef} from "react"
import Box from "@mui/material/Box"
import lineRegionsGraphStyles from "./styles/lineRegionsGraphStyles"
import {getStyles} from "../../../../../utils/utils/commonUtils"
import Typography from "@mui/material/Typography"
import RegionsGraphLine from "./components/RegionsGraphLine"
import {observer} from "mobx-react"
import Tooltip from "@mui/material/Tooltip"
import LineGraphFooter from "./components/LineGraphFooter"

function LineRegionsGraph({store}) {
    const classes = getStyles(lineRegionsGraphStyles)
    const {collection, maxOrderTotal} = store
    return collection.map(({nameDistrict, orders}) => <Box className={"aln-center"}>
        <Box sx={{width: "35%"}}>
            <Tooltip title={<Typography variant={"body1"}>{nameDistrict}</Typography>} placement={"top-end"}>
                <Typography variant={"body1"} sx={classes.localityText}>
                    {nameDistrict}
                </Typography>
            </Tooltip>

        </Box>
        <Box sx={{width: "65%"}}>
            <RegionsGraphLine orders={orders} maxValue={maxOrderTotal}/>
        </Box>
    </Box>)
}

export default observer(LineRegionsGraph)