import { makeAutoObservable, reaction } from 'mobx'
import { getDashboardFinancialSources, getDashboardSources } from '../../../services/ApiService'
import { getComp } from '../../../utils/utils/DI'
import AdditionRegionsFiltersStore from './AdditionRegionsFiltersStore'
import { gState } from '../../../utils/controllers/GlobalStateController'

class AdditionStatStore {
    isLoading = true

    constructor() {
        makeAutoObservable(this)
        this.filterStore = new AdditionRegionsFiltersStore(true)
    }

    reactionTerritory = reaction(
        () => this.filterStore.data.territory,
        (data) => {
            this.filterStore.data.district = gState['intl'].formatMessage({ id: 'Все округа' })
        }
    )

    reactionFilterParams = reaction(
        () => this.filterStore.noEmptyFilters,
        (data) => {
            if (!this.isLoading) {
                if (data.territory === 'Все регионы') {
                    this.filterStore.data.district = gState['intl'].formatMessage({ id: 'Все округа' })
                }
                this.getData()
            }
        }
    )

    
    data = []
    dataDotation = []
    dataForCanvas = null
    totalColor = '#2067C1'
    totalCount = 0
    colorsArr = ['rgba(2, 58, 102, 1)', 'rgba(8, 135, 120, 1)', 'rgba(22, 176, 37, 1)', 'rgb(177 177 177)']
   

   
    crmData = []
    crmDataForCanvas = null
    crmTotalColor = '#000000'
    crmTotalCount = 0
    crmColorsArr = ['#6EAA74', '#90DC97', '#9CE4B8', '#A1BE4D', '#718527']

   
    disabledCanvasColor = ['#A5A5A5']

    
    
    getData = async () => {
        const emptyYearTemplate = {
            year: 0, 
            total: 0,
            financialSources: [
                {
                    financialSourceId: null,
                    count: 0
                }
            ]
        };
        try {
            this.isLoading = true;
            const validatedParams = this.filterStore.getValidateParams();
    
            const dashboardData = [] /*await getDashboardSources(validatedParams);*/
            if (!dashboardData) {
                throw new Error('Не удалось получить данные для дашборда.');
            }
            this.data = dashboardData;
            let yearData
            const financialData = await getDashboardFinancialSources(validatedParams);
            if (!financialData || financialData.length === 0) {
                yearData = { ...emptyYearTemplate, year: +this.filterStore.data.year };
                // throw new Error('Не удалось получить финансовые данные.');
            }
    
            yearData = financialData.find((el) => el.year === +this.filterStore.data.year);
            if (!yearData) {
                console.warn(`Не найдены данные за ${this.filterStore.data.year} год. Используется шаблон с пустыми данными.`);
                yearData = { ...emptyYearTemplate, year: +this.filterStore.data.year };
            }
    
            const transformedData = this.transformData([yearData]);
            this.dataDotation = transformedData;
    
            this.calcTotal();
            this.normalizeData();
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        } finally {
            this.isLoading = false;
        }
    }
    
    
    transformData = (input) => {
        const namesMapping = {
            1: 'Полная дотация',
            2: 'Частичная дотация',
            3: 'За счёт абонента',
            null: 'Не указан',
        };
    
        const colorMapping = {
            1: '#2067C1',
            2: '#8FBFF6',
            3: '#7091B7',
            null: '#898989',
        };
    
        if (!input || input.length === 0 || !input[0].financialSources) {
            // Если массив пустой или financialSources отсутствует, возвращаем массив с нулевыми значениями
            return Object.keys(namesMapping).map((key) => ({
                name: namesMapping[key],
                color: colorMapping[key],
                count: 0,
                percentOfTotal: 0,
            }));
        }
    
        return input[0].financialSources.map((source) => {
            const percentOfTotal = (source.count / input[0].total) * 100;
            return {
                name: namesMapping[source.financialSourceId],
                color: colorMapping[source.financialSourceId],
                count: source.count,
                percentOfTotal: Math.round(percentOfTotal * 100) / 100,
            };
        });
    }
    

    normalizeData = () => {
        let baseObj = {
            1: {
                name: 'Полная дотация',
                count: 0,
            },

            2: {
                name: 'Частичная дотация',
                count: 0,
            },

            3: {
                name: 'За счёт Абонента',
                count: 0,
            },
            4: {
                name: 'Не указан',
                count: 0,
            },
        }

        let crmBaseData = {
            1: {
                name: 'Администрация',
                count: 0,
            },
            2: {
                name: 'Менеджер офиса',
                count: 0,
            },
            3: {
                name: 'Колл-центр',
                count: 0,
            },

            4: {
                name: 'Дилер',
                count: 0,
            },
            5: {
                name: 'Другое',
                count: 0,
            },
        }

        this.data.forEach((item) => {
            if (item.source === 3) {
                if (item.sender.toLowerCase() === 'Администрация'.toLocaleLowerCase()) {
                    return (crmBaseData[1].count += item.count)
                } else if (item.sender.toLowerCase() === 'Менеджер офиса'.toLowerCase()) {
                    return (crmBaseData[2].count += item.count)
                } else if (item.sender.toLowerCase() === 'Колл-центр'.toLowerCase()) {
                    return (crmBaseData[3].count += item.count)
                } else if (item.sender.toLowerCase() === 'Дилер'.toLowerCase()) {
                    return (crmBaseData[4].count += item.count)
                } else if (!item.sender) {
                    return (crmBaseData[5].count += item.count)
                }

                return (crmBaseData[5].count += item.count)
            }
        })

        this.dataDotation.forEach((item) => {
            if (item.name === 'Полная дотация') {
                baseObj[1].count += item.count
            } else if (item.name === 'Частичная дотация') {
                baseObj[2].count += item.count
            } else if (item.name === 'За счёт абонента') {
                baseObj[3].count += item.count
            } else if (item.name === 'Не указан') {
                baseObj[4].count += item.count
            }
        })
        let dataArr = Object.values(baseObj)
        let crmDataArr = Object.values(crmBaseData)

        this.dataForCanvas = this.formationDataForCanvas(dataArr)
        this.crmDataForCanvas = this.formationDataForCanvas(crmDataArr)

        dataArr = this.addColors(dataArr, this.colorsArr)
        crmDataArr = this.addColors(crmDataArr, this.crmColorsArr)

        this.dataDotation = dataArr
        this.crmData = crmDataArr

        this.calcPercents(this.dataDotation, this.totalCount)
        this.calcPercents(this.crmData, this.crmTotalCount)
    }

    calcTotal = () => {
        
        let sum = this.dataDotation.reduce((acc, currentItem) => {
            return (acc += currentItem.count)
        }, 0)
       
        let totalCrm = this.data.reduce((acc, currentItem) => {
            if (currentItem.source === 3) {
                return (acc += currentItem.count)
            }
            return acc
        }, 0)

        this.crmTotalCount = totalCrm
        this.totalCount = sum
    }

    calcPercents = (data, total) => {
        const result = data.map((item) => {
            const percentOfTotal = Math.floor((item.count * 100) / total)

            if (item.count > 0 && percentOfTotal === 0) {
                item.percentOfTotal = '< 1'
            } else if (item.count > 0 && percentOfTotal > 0) {
                item.percentOfTotal = percentOfTotal
            } else if (item.count === 0) {
                item.percentOfTotal = 0
            }

            return item
        })
        return result
    }

    addColors = (data, colors) => {
        const result = data.map((item, index) => {
            item.color = colors[index]
            return item
        })
        return result
    }

    formationDataForCanvas = (data) => {
        const obj = {}
        data.forEach((item) => {
            if (item.name) {
                obj[item.name] = item.count
            }
        })

        return obj
    }
}

export default AdditionStatStore
