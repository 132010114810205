import React, {useEffect} from "react"
import Piechart from "./Piechart"
import {observer} from "mobx-react"
import {Box, Tooltip, Typography, Zoom} from "@mui/material"
import {useState} from "react"
import StatisticTableItem from "../StatisticTable/StatisticTableItem"
import TooltipButton from "src/components/CustomTooltip/TooltipButton"

const RadialGraph = ({
    circleWidth = 234,
    circleHeight = 234,
    colorsArr,
    holeSize = 0.45,
    total,
    totalColor,
    dataForCanvas,
    disabledCanvasColor,
    data,
    tooltipButtonContent,
    tooltipTitle,
    titleSize,
    totalText,
    tooltipPlacement
}) => {
    const id = Date.now()
    const emptyDataForCanvas = {
        empty: 1
    }

    const [graph, setGraph] = useState(null)
    const [currentInfo, setCurrentInfo] = useState(null)

    useEffect(() => {
        const radialGraphCanvas = document.getElementById(id)
        const myPiechart = new Piechart({
            canvas: radialGraphCanvas,
            data: total ? dataForCanvas : emptyDataForCanvas,
            colors: total ? colorsArr : disabledCanvasColor,
            holeSize
        })

        setGraph(myPiechart)
        myPiechart.draw()

        return () => {
            myPiechart.cleanup()  // условный метод для удаления ресурсов, если такой существует
        }
    }, [total, dataForCanvas, colorsArr])

    useEffect(() => {
        let info = {}
        data.filter((item) => {
            if (item && item.color === graph?.hoveredSliceColor) {
                info = {...item}

                return item
            }
        })

        setCurrentInfo(info)
    }, [graph?.hoveredSliceColor])

    return (
        <Box
            sx={{
                position: "relative"
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: `translate(-50%, calc(-50% - ${totalText ? "11px" : "0px"}))`,
                    zIndex: 10
                }}
            >
                {totalText ? (
                    <Typography
                        sx={{
                            color: total ? totalColor : disabledCanvasColor,
                            fontSize: "11px",
                            fontWeight: "400",
                            cursor: "default",
                            textAlign: "center",
                            marginBottom: "4px",
                            lineHeight: "16px"
                        }}
                    >
                        {totalText}
                    </Typography>
                ) : null}
                <Typography
                    sx={{
                        color: total ? totalColor : disabledCanvasColor,
                        fontSize: titleSize || "1.6rem",
                        fontWeight: "700",
                        cursor: "default",
                        textAlign: "center"
                    }}
                >
                    {total}
                </Typography>
            </Box>

            <Tooltip
                TransitionComponent={Zoom}
                followCursor
                placement="top"
                title={currentInfo?.color ? <StatisticTableItem item={currentInfo} /> : null}
            >
                <Box sx={{borderRadius: "50%", padding: "5px"}}>
                    <canvas
                        id={id}
                        width={circleWidth}
                        height={circleHeight}
                        style={{
                            borderRadius: "50%",
                            cursor: "pointer",
                            width: circleWidth,
                            height: circleHeight
                        }}
                    ></canvas>
                </Box>
            </Tooltip>
            <Box
                sx={{
                    position: "absolute",
                    right: "0",
                    bottom: "10px"
                }}
            >
                <TooltipButton
                    placement={tooltipPlacement}
                    content={tooltipButtonContent}
                    title={tooltipTitle}
                    titleIcon={"blue"}
                />
            </Box>
        </Box>
    )
}

export default observer(RadialGraph)
