import { makeAutoObservable } from 'mobx'
import { gState } from 'src/utils/controllers/GlobalStateController'
import MapStatsStore from './MapStatsStore'

class MapWidgetStore {
    currentZoom = window.SAT.config.startZoom
    currentLatitude
    currentLongitude
    hoverObject = null
    layer = 'installed'

    initViewState = {
        longitude: this.currentLongitude ? this.currentLongitude : window.SAT.config.startLongitude,
        latitude: this.currentLatitude ? this.currentLatitude : window.SAT.config.startLatitude,
        zoom: this.currentZoom,
        minZoom: window.SAT.config.mapMinZoom,
        maxZoom: window.SAT.config.mapMaxZoom,
    }
    viewState = this.initViewState

    setViewState = (state) => {
        this.viewState = state
    }

    handleMapParamsChange = (e, navigateControl) => {
        e.viewState.zoom = Math.trunc(e.viewState.zoom)
        if (!navigateControl) {
            if (
                e.viewState.longitude >= 40.27562699126668 ||
                e.viewState.longitude <= 31.494303986437753 ||
                e.viewState.latitude >= 50.233557010833245 ||
                e.viewState.latitude <= 45.66484954428557
            ) {
                return
            }
        }

        if (navigateControl) {
            e.maxZoom = window.SAT.config.mapMaxZoom
            e.minZoom = window.SAT.config.mapMinZoom
            e.zoom = e.zoom > e.maxZoom ? e.maxZoom : e.zoom
            e.zoom = e.zoom < e.minZoom ? e.minZoom : e.zoom
            this.updateViewState({ viewState: e })
            if(e.viewState.zoom < 13 && e.viewState.zoom > 5){
            return this.setViewState(e)
            }
        }

        if (e.viewState) {
            this.updateViewState({ viewState: e.viewState })
        }
        if(e.viewState.zoom < 13 && e.viewState.zoom > 5){

            this.setViewState(e.viewState)
        }
    }
    mapStatStore = new MapStatsStore()
    constructor() {
        makeAutoObservable(this)
    }

    hoverPoint = (obj) => {
        if (obj) {
            this.hoverObject = obj
        } else {
            this.hoverObject = null
        }
    }

    changeLayer = (layer) => {
        this.layer = layer
    }

    filterDataByCount = (data) => {
        if (data) {
            return data.filter((item) => !!this.getOrdersCountSumm(item))
        }
    }

    get scatterplotColor() {
        // return this.layer === 'installed' ? [65, 185, 77] : [44, 93, 100]
        return window.SAT.config.scatterplotColor
    }

    get scatterplotBorderColor() {
        return window.SAT.config.scatterplotBorderColor
    }

    get lineWidthMinPixels() {
        return window.SAT.config.lineWidthMinPixels
    }

    getZoomValueName = (zoom) => {
        if (zoom < 8) {
            return 'territories'
        } else if (zoom > 10) {
            return 'localities'
        } else {
            return 'districts'
        }
    }

    setCurrentZoom = (zoom) => {
        const newZoomName = this.getZoomValueName(Math.round(zoom))
        const currentZoomName = this.getZoomValueName(this.currentZoom)
        if (newZoomName !== currentZoomName) {
            if (newZoomName === 'territories') {
                this.mapStatStore.load({ name: 'territories' })
            } else if (newZoomName === 'districts') {
                this.mapStatStore.load({ name: 'districts' })
            } else {
                this.mapStatStore.load({ name: 'localities' })
            }
            this.currentZoom = zoom
        }
    }

    get currentScale() {
        if (this.currentZoom < 8) {
            return 0.5
        } else if (this.currentZoom > 10) {
            return 1
        } else {
            return 1
        }
    }

    get zoomLayoutName() {
        if (this.currentZoom < 8) {
            return 'Регион'
        } else if (this.currentZoom > 10) {
            return 'Населенный пункт'
        } else {
            return 'Округ (ГО или МО)'
        }
    }

    get currentRoundSettings() {
        if (this.currentZoom < 8) {
            return window.SAT.config.territories
        } else if (this.currentZoom > 10) {
            return window.SAT.config.localities
        } else {
            return window.SAT.config.districts
        }
    }

    get mapData() {
        const name = this.getZoomValueName(this.currentZoom)
        if (name === 'territories') {
            return this.filterDataByCount(this.mapStatStore.territories)
        } else if (name === 'districts') {
            return this.filterDataByCount(this.mapStatStore.districts)
        } else {
            return this.filterDataByCount(this.mapStatStore.localities)
        }
    }
    getScatterplotCoordinates = ({ latitude, longitude }) => {
        return [longitude, latitude]
    }
    getInstalledOrderCount = (territories) => {
        let summ = 0
        if (this.layer === 'installed') {
            if (territories.installedOrderReasonsCount) {
                summ += territories.installedOrderReasonsCount.reduce((acc, el) => {
                    if (el.reason === 1 || el.reason === 2) {
                        acc += el.count
                    }
                    return acc
                }, 0)
            }
        } else if (this.layer === 'noInstalled') {
            if (territories.noInstalledOrderReasonsCount) {
                summ += territories.noInstalledOrderReasonsCount.reduce((acc, el) => {
                    if (el.reason === 1 || el.reason === 2) {
                        acc += el.count
                    }
                    return acc
                }, 0)
            }
        }

        return summ
    }
    getInstalledOrderOptions = (territories) => {
        let summ = this.getInstalledOrderCount(territories)
        const percent = (summ / this.getOrdersCountSumm(territories)) * 100 || 0
        return {
            name: gState.intl.formatMessage({ id: 'Установки' }),
            color: '#41B94D',
            count: summ,
            percentOfTotal: Math.round(percent),
        }
    }
    getReplacementOrderCount = (territories) => {
        let summ = 0
        if (this.layer === 'installed') {
            if (territories.installedOrderReasonsCount) {
                summ += territories.installedOrderReasonsCount.reduce((acc, el) => {
                    if (el.reason === 3) {
                        acc += el.count
                    }
                    return acc
                }, 0)
            }
        } else if (this.layer === 'noInstalled') {
            if (territories.noInstalledOrderReasonsCount) {
                summ += territories.noInstalledOrderReasonsCount.reduce((acc, el) => {
                    if (el.reason === 3) {
                        acc += el.count
                    }
                    return acc
                }, 0)
            }
        }
        return summ
    }
    getReplacementOrderOptions = (territories) => {
        let summ = this.getReplacementOrderCount(territories)
        const percent = (summ / this.getOrdersCountSumm(territories)) * 100 || 0
        return {
            name: gState.intl.formatMessage({ id: 'Замены (Горынычи)' }),
            color: '#2C5D64',
            count: summ,
            percentOfTotal: Math.round(percent),
        }
    }

    getOrdersCountSumm = (territories) => {
        let summ = 0
        if (this.layer === 'installed') {
            if (territories.installedOrderReasonsCount) {
                summ += territories.installedOrderReasonsCount.reduce((acc, el) => {
                    acc += el.count
                    return acc
                }, 0)
            }
        } else if (this.layer === 'noInstalled') {
            if (territories.noInstalledOrderReasonsCount) {
                summ += territories.noInstalledOrderReasonsCount.reduce((acc, el) => {
                    acc += el.count
                    return acc
                }, 0)
            }
        }

        return summ
    }

    getOrdersCountOptions = (territories) => {
        return {
            name: gState.intl.formatMessage({ id: 'Всего установок' }),
            count: this.getOrdersCountSumm(territories),
            percentOfTotal: 100,
        }
    }

    getOrdersInstallInfo = (territory) => {
        return [
            this.getOrdersCountOptions(territory),
            this.getReplacementOrderOptions(territory),
            this.getInstalledOrderOptions(territory),
        ]
    }

    getTerritoryName = (territory) => {
        let name = ''
        // if(territory.territory){
        //     name += territory.territory.name + ", "
        // }
        if (territory.district) {
            name += territory.district.name + ', '
        }
        name += territory.name
        return name
    }

    updateViewState = ({ viewState, interactionState }) => {
        const { latitude, longitude, zoom } = viewState
        this.setCurrentZoom(zoom)
        this.currentLatitude = latitude
        this.currentLongitude = longitude
    }
}

export default MapWidgetStore