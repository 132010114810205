import {Box, FormControl} from "@mui/material"
import React from "react"
import {useIntl} from "react-intl"
import FormInput from "../../../../components/customElements/FormInput"
import {observer} from "mobx-react"
import {getStyles} from "src/utils/utils/commonUtils"
import filtersStyles from "src/views/AdditionalSummary/styles/filtersStyles"
import withWidth, {isWidthUp} from "@mui/material/Hidden/withWidth"

const AdditionRegionsFilters = ({filterStore,column,width, flag}) => {
    const intl = useIntl()
    const classes = getStyles(filtersStyles)

    const filtersData = [
        {
            type: "select",
            propName: "year",
            items: ['2023','2024'],
            defaultValue: filterStore.year,
            label: intl.formatMessage({id: "Год"}),
        },
        {
            type: "select",
            propName: "territory",
            items: filterStore.regions,
            defaultValue: filterStore.radialGraph ?
                intl.formatMessage({id: "Все регионы"}) :
                intl.formatMessage({id: "ДНР"}),
            label: intl.formatMessage({id: "Регион"}),
        },
        {
            type: "select",
            propName: "district",
            items: filterStore.radialGraph ? filterStore.locations : [intl.formatMessage({id: "Все округа"})],
            defaultValue: intl.formatMessage({id: "Все округа"}),
            label: intl.formatMessage({id: "Округ"})
        },
    ]

    return (
        <Box sx={{...classes.container,  ...(column || !isWidthUp("sm",width) ? classes.columnFilters : {})
        }}>
            {filtersData.map((filter) => {
                if(flag && filter.propName === 'year') return null
                return (
                    <FormControl key={filter.propName}>
                        <FormInput {...filter} api={filterStore} fullWidth/>
                    </FormControl>
                )
            })}
        </Box>
    )
}

export default withWidth()(observer(AdditionRegionsFilters))
