const lineRegionsGraphStyles = (theme) => ({
    graphLineContainer: {
        display: "flex",
        height: "30px",
        alignItems: "center",
    },
    graphLineDivider: {
        width: "10px",
        height: "100%",
        borderRight: `1px solid ${theme.palette.text.graySemidark}`,
        display: "flex",
        alignItems: "center",
    },
    vector: {
        width: "100%",
        borderTop: `1px solid ${theme.palette.text.graySemidark}`
    },
    graphLineItem: {
        height: "14px",
        display: "flex",
        justifyContent: "center",
        width: 0,
        transition: "width 3s",
    },
    graphLineItemText: {
        color: "white",
        fontSize: "12px",
        lineHeight: 1.2,
        [theme.breakpoints.down("sm")]:{
            fontSize: "11px",
            lineHeight: "14px",
        }
    },
    localityText: {
        textAlign: "end",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer"
    }
})

export default lineRegionsGraphStyles