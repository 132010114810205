const LastChildSpaceStyles = (theme) => ({
    root: {
        minWidth: "24px",
        height: "1px",
        [theme.breakpoints.up(1776)]: {
            display: "none"
        }
    }
})

export default LastChildSpaceStyles
