import {observer} from "mobx-react"
import React, {useMemo} from "react"
import Box from "@mui/material/Box"
import StatisticTable from "src/views/AdditionalSummary/components/common/StatisticTable/StatisticTable"
import Typography from "@mui/material/Typography"
import {getStyles} from "src/utils/utils/commonUtils"
import AdditionalRadialsStyles from "src/views/AdditionalSummary/styles/AdditionalRadialsStyles"
import replacementGraphStyles from "src/views/AdditionalSummary/styles/replacementGraphStyles"
import ReplacementGraph from "src/views/AdditionalSummary/ReplacementGraphWidget/components/ReplacementGraph"

function GraphContent({store, data, iconTooltipData}) {
    const classes = getStyles(replacementGraphStyles)
    const tableData = store.getTableData(data)
    const graphData = useMemo(() => store.getGraphOptions(data), [data])
    const tooltipData = useMemo(() => store.getTableData(data, true), [data])
    return (
        <Box sx={classes.graphContent}>
            <Typography variant={"h2"} sx={classes.contentTitle}>
                {data.name}
            </Typography>
            <ReplacementGraph
                data={graphData}
                totalColor={store.totalReplacementColor}
                tooltipData={tooltipData}
                iconTooltipData={iconTooltipData}
            />
            <Box sx={classes.table}>
                <StatisticTable data={tableData}/>
            </Box>
        </Box>
    )
}

export default observer(GraphContent)