import { makeAutoObservable } from 'mobx'
import { getMapRegionStats } from 'src/services/ApiService'

class MapStatsStore {
    districts = null
    localities = null
    territories = null
    scaleOption = {
        territories: 2, //регионы
        districts: 4, //муницыпальные округа
        localities: 8, //населенные пункты
    }

    statsOptions = {
        withOtherStatus: 2, //количество заявок в разных статусах
        withoutStatuses: 4, //причины установки без привязки к статусам
        installed: 8, //причины установки для установленных заявок. Установленными считаются заявки в статусах: 6, 9, 12, 13, 14, 15, 16
        uninstalled: 16, // причины установки для неустановленных заявок. Неустановленными считаются заявки в статусах: 1, 2, 4, 5
    }

    scale = this.scaleOption.territories
    stats = this.statsOptions.installed + this.statsOptions.uninstalled
    isLoading = false
    constructor() {
        makeAutoObservable(this)
    }

    setScale = (name) => {
        const newScale = this.scaleOption[name]
        if (newScale !== this.scale) {
            this.scale = newScale
        }
    }

    reLoad = () => {}

    load = ({ name = 'districts', reload = false }) => {
        // if(!this.isLoading) {
        //     this.isLoading =
        if (reload) {
            this.districts = null
            this.localities = null
            this.territories = null
        }
        if (this[name]) {
            return
        }
        this.setScale(name)
        const data = {
            stats: this.stats,
            scale: this.scale,
        }
        getMapRegionStats(data)
            .then((res) => {
                if (res.districts) {
                    this.districts = res.districts
                }
                if (res.localities) {
                    this.localities = res.localities
                }
                if (res.territories) {
                    this.territories = res.territories
                }
            })
            .finally(() => (this.isLoading = false))
        // }
    }
}

export default MapStatsStore
