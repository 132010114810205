import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { getStyles } from 'src/utils/utils/commonUtils'
import DeckGL from '@deck.gl/react'
import { MapWidgetStyles } from './MapWidgetStyles'
import { BitmapLayer, ColumnLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers'
import { TileLayer } from '@deck.gl/geo-layers'
import { _MapContext as MapContext } from 'react-map-gl'
import { NavigationControl } from 'react-map-gl'
import './stylesNavigation.css'
import { Box } from '@mui/system'
import PointerTooltip from 'src/views/AdditionalSummary/components/MapWidget/components/PointerTooltip/PointerTooltip'
import { useIntl } from 'react-intl'
import MapWidgetStore from './MapWidgetStore'
import { Typography } from '@mui/material'
import { css } from '@emotion/css'
import CoordinatePicker from './components/DisplayCoordinates/CoordinatePicker'
import { isAdminType2 } from '../../../../services/AccountService'

function MapWidget() {
    const classes = getStyles(MapWidgetStyles)
    const intl = useIntl()
    const [cursorActive, setCursorActive] = useState(false)
    const [store] = useState(() => new MapWidgetStore())

    useEffect(() => {
        store.mapStatStore.load({ name: 'districts' })

        const mapWidgetInterval = window.SAT.config.dashboardMapWidgetLoadInterval || 900000

        const interval = setInterval(() => {
            store.mapStatStore.load({
                name: store.getZoomValueName(store.currentZoom),
                reload: true,
            })
        }, mapWidgetInterval)

        return () => {
            clearInterval(interval)
        }
    }, [])
    const {
        initViewState,
        updateViewState,
        mapData,
        getScatterplotCoordinates,
        getOrdersCountSumm,
        hoverPoint,
        scatterplotColor,
        viewState,
        handleMapParamsChange,
        scatterplotBorderColor,
        lineWidthMinPixels,
        currentScale,
        currentRoundSettings,
        zoomLayoutName,
    } = store
    const minusBtn = document.querySelector('.mapboxgl-ctrl .mapboxgl-ctrl-zoom-out')
    const plusBtn = document.querySelector('.mapboxgl-ctrl .mapboxgl-ctrl-zoom-in')

    const disableBtn = (btn) => {
        btn.disabled = true
        btn.style.background = '#e7e7e7'
        btn.style.cursor = 'default'
    }

    const enableBtn = (btn) => {
        btn.disabled = false
        btn.style.background = 'white'
        btn.style.cursor = 'pointer'
    }

    useEffect(() => {
        if (minusBtn) minusBtn.style.borderRadius = '0 0 4px 4px'

        if (viewState.zoom === viewState.maxZoom && plusBtn) {
            disableBtn(plusBtn)
        } else if (plusBtn) {
            enableBtn(plusBtn)
        }
        if (viewState.zoom === viewState.minZoom && minusBtn) {
            disableBtn(minusBtn)
        } else if (minusBtn) {
            enableBtn(minusBtn)
        }
    }, [viewState.zoom, plusBtn, minusBtn])

    const setTileSubLayers = (props) => {
        const {
            bbox: { west, south, east, north },
        } = props.tile
        return [
            new BitmapLayer(props, {
                id: props.id + 'tile',
                data: null,
                image: props.data,
                bounds: [west, south, east, north],
            }),
        ]
    }
    const tileLayer = new TileLayer({
        data: `${window.SAT.config.tilesUrl}/tiles/{z}/{x}/{y}.png`,
        minZoom: window.SAT.config.mapMinZoom,
        maxZoom: window.SAT.config.mapMaxZoom,
        tileSize: 256,
        extent: window.SAT.config.mapBorder,

        renderSubLayers: setTileSubLayers,
    })

    const territoriesLayer = new ScatterplotLayer({
        id: 'scatterplot-layer',
        data: mapData,
        pickable: true,
        opacity: window.SAT.config.scatterplotOpacity,
        stroked: true,
        filled: true,
        // radiusScale: 1.5,
        // radiusMinPixels: 24,
        // radiusMaxPixels: 64,
        // radiusUnits: 'pixels',
        lineWidthMinPixels: lineWidthMinPixels,
        radiusScale: currentRoundSettings.radiusScale,
        radiusMinPixels: currentRoundSettings.min,
        radiusMaxPixels: currentRoundSettings.max,
        onHover: hoverPoint,

        getPosition: (d) => getScatterplotCoordinates(d.center),
        getRadius: (d) => getOrdersCountSumm(d),
        getFillColor: (d) => scatterplotColor,
        getLineColor: (d) => scatterplotBorderColor,
    })

    const [cursorCoordinates, setCursorCoordinates] = useState([])
    const [savedСoordinates, setSavedСoordinates] = useState([])
    const [stopEvent, setStopEvent] = useState(false)

    const showСoordinates = (e) => {
        if (!stopEvent) {
            if (cursorActive) {
                setCursorCoordinates(e.coordinate)
            }
        }
    }

    const saveCoordinates = (e) => {
        if (!stopEvent) {
            setSavedСoordinates(cursorCoordinates)
        }
    }

    return (
        <Box sx={classes.root}>
            <DeckGL
                viewState={viewState}
                // initialViewState={store.initViewState}
                controller={{
                    dragPan: true,
                    dragRotate: false,
                    scrollZoom: {
                        speed: 1,
                    },
                }}
                onViewStateChange={handleMapParamsChange}
                layers={[tileLayer, territoriesLayer]}
                ContextProvider={MapContext.Provider}
                onHover={showСoordinates}
                onClick={saveCoordinates}
                getCursor={({ isHovering, isDragging }) =>
                    cursorActive ? 'default' : isHovering ? 'pointer' : isDragging ? 'grabbing' : 'grab'
                }
            >
                <Box sx={classes.navigator}>
                    <NavigationControl
                        showZoom={true}
                        showCompass={false}
                        zoomInLabel={intl.formatMessage({ id: 'Увеличить' })}
                        zoomOutLabel={intl.formatMessage({ id: 'Уменьшить' })}
                        onViewportChange={(e) => handleMapParamsChange(e, true)}
                    />
                </Box>
                <Box sx={classes.layoutName}>
                    <Typography sx={classes.zoomLayoutTitle}>
                        {intl.formatMessage({ id: 'Уровень детализации:' })}
                    </Typography>
                    <Typography sx={classes.zoomLayoutName}>{zoomLayoutName}</Typography>
                </Box>
                {isAdminType2() && (
                    <Box
                        sx={classes.displayingCoordinates}
                        onMouseEnter={() => setStopEvent(true)} // Флаг активируется при наведении курсора мыши
                        onMouseLeave={() => setStopEvent(false)}
                    >
                        <CoordinatePicker
                            cursorActive={cursorActive}
                            setCursorActive={setCursorActive}
                            cursorCoordinates={cursorCoordinates}
                            savedСoordinates={savedСoordinates}
                        />
                    </Box>
                )}

                <Box sx={classes.scaleWrapper}>
                    <input
                        className='mapPositionRange'
                        type='range'
                        step='1'
                        min={window.SAT.config.mapMinZoom}
                        max={12}
                        value={viewState.zoom}
                    />
                </Box>
                <PointerTooltip store={store} />
            </DeckGL>
        </Box>
    )
}

export default observer(MapWidget)
