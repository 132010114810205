const AdditionalRadialsStyles = (theme) => ({
    wrapper: {
        flexGrow: 1,
        padding: "16px 20px 23px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "12px",
        background: theme.palette.background.grayLight,
        // position: "relative",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px",
            borderRadius: 0,
            minHeight: "100%"
        }
    },
    wrapper__content: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    title: {
        color: theme.palette.text.graySemidark,
        fontSize: "14px"
    },
    subtitle: {
        fontSize: "12px",
        fontWeight: 700,
        color: "#000",
        textTransform: "uppercase"
    },

    buttonsWrapper: {
        marginLeft: "8px",
        display: "flex",
        gap: "8px",
        right: "48px",
        top: "12px",
        [theme.breakpoints.down("sm")]: {
            top: 0,
            right: 2
        }
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "space-around",
        gap: "8px",
        marginTop: "32px",
        width: "100%",
        height: "100%",
        [theme.breakpoints.down(700)]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "32px"
        }
    },

    itemWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "16px",
        minWidth: "233px",
        width: "100%"
    },

    item__title: {display: "flex"},

    item__listElement: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "4px",
        gap: "20px"
        // width: "100%"
    },
    filterTitle: {
        color: theme.palette.text.graySemidark,
        fontFamily: "PT Root UI",
        fontSize: "11px",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "0.385px",
        textTransform: "uppercase",
        paddingLeft: "16px"
    },
    table: {
        border: "1px solid #E0DFDF",
        padding: "16px",
        width: "100%",
        boxShadow: "none"
    },
    iconButton: {
        padding: 0,
        height: "25px",
        "&:hover": {
            backgroundColor: "inherit !important"
        }
    },
    desktopFiltersContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom: "48px"
    }
})

export default AdditionalRadialsStyles
