import React from "react"
import LastChildSpaceStyles from "./style/LastChildSpaceStyles"
import {getStyles} from "../../../utils/utils/commonUtils"
import {Box} from "@mui/system"

const LastChildSpace = () => {
    const classes = getStyles(LastChildSpaceStyles)
    return <Box sx={classes.root}></Box>
}

export default LastChildSpace
