const replacementGraphStyles = (theme, { color }) => {
    return {
        paper: {
            display: 'flex',
            gap: '24px',
            padding: '32px 40px 32px 40px',
            // overflowX: "scroll",
            overflowY: 'auto',
            background: theme.palette.background.grayLight,
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
            [theme.breakpoints.down(1776)]: {
                padding: '16px 0 24px 40px',
            },
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
                padding: '16px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '8px 8px 63px 8px ',
                flexDirection: 'column',
            },
        },
        header: {
            maxWidth: '290px',
            display: 'flex',
            flexDirection: 'column',
            gap: '44px',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                maxWidth: '100%',
            },
            [theme.breakpoints.down('lg')]: {
                width: '100%',
                maxWidth: '100%',
            },
        },
        statusesContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '216px',
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        graphsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '16px',
            [theme.breakpoints.down('lg')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                justifyItems: 'center',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        graphContent: {
            width: 264,
        },
        contentTitle: {
            fontSize: '12px',
            textTransform: 'uppercase',
            textAlign: 'center',
            marginBottom: '-60px',
        },
        table: {
            marginTop: '-20px',
        },
        graphContainer: {
            width: '236px',
            overflow: 'hidden',
            margin: '0 auto',
            position: 'relative',
        },
        graphContentContainer: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, 35%)`,
            zIndex: 10,
        },
        graphTotalContainer: {
            width: 'fit-content',
            margin: '0 auto',
        },
        totalIcon: {
            position: 'absolute',
            right: '1px',
            top: '64px',
        },
        graphTotalTitle: {
            color: color,
            fontSize: '11px',
            fontWeight: '400',
            cursor: 'default',
            textAlign: 'center',
            marginBottom: '4px',
            lineHeight: '16px',
        },
        graphTotal: {
            color: color,
            fontSize: '36px',
            fontWeight: '700',
            cursor: 'default',
            textAlign: 'center',
        },
        graphCountsContainer: {
            position: 'absolute',
            bottom: '34px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        graphCount: {
            fontSize: '11px',
        },
    }
}

export default replacementGraphStyles
