import FormStore from '../../../utils/stores/FormStore'
import { computed, makeObservable, observable } from 'mobx'
import { gState } from '../../../utils/controllers/GlobalStateController'
import { getComp } from '../../../utils/utils/DI'

const initState = {
    from: null,
    to: null,
    territory: '',
    district: '',
    groupByYear: 'true',
    year: '2024',
}

const validateData = {
    territory: { required: true },
}

class AdditionRegionsFiltersStore extends FormStore {
    constructor(radialGraph) {
        super({ initState, validateData })
        this.territoryStore = getComp('TerritoryStore')
        this.radialGraph = radialGraph
        makeObservable(this, {
            regions: computed,
            locations: computed,
        })
    }

    get regions() {
        const territories = this.territoryStore.collection
        const result = [
            ...(this.radialGraph ? [gState['intl'].formatMessage({ id: 'Все регионы' })] : []),
            ...territories,
        ]

        return result
    }

    get locations() {
        const res = (this.territoryStore.territories[this.data.territory]?.Territory.Districts || []).map(
            (el) => el.Name
        )
        const result = [gState['intl'].formatMessage({ id: 'Все округа' }), ...res]
        return result
    }

    getValidateParams = () => {
        const params = {}
        if (this.data.territory !== 'Все регионы') {
            params.territory = this.data.territory
        }
        if (this.data.district !== 'Все округа') {
            params.district = this.data.district
        }

        params.groupByYear = this.data.groupByYear

        return params
    }

    get noEmptyFilters() {
        const result = {}
        for (let key in this.data) {
            if (this.data[key]) {
                result[key] = this.data[key]
            }
        }
        return result
    }

    clear = () => {
        this.setData(initState)
    }
}

export default AdditionRegionsFiltersStore
