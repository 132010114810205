import React from 'react'
import RadialGraph from './RadialGraph/RadialGraph'
import { observer } from 'mobx-react'
import ContentWrapper from './ContentWrapper'
import StatisticTable from './StatisticTable/StatisticTable'
import { Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import TooltipInfoContent from 'src/components/CustomTooltip/TooltipInfoContent'

const AdditionalBaseItem = observer(
    ({
        title,
        dataForCanvas,
        data,
        colorsArr,
        total,
        totalColor,
        maxWidth,
        holeSize,
        disabledCanvasColor,
        circleWidth,
        circleHeight,
        graphTooltipData,
        totalText,
    }) => {
        const intl = useIntl()

        return (
            <>
                {data.length ? (
                    <ContentWrapper title={title} maxWidth={maxWidth}>
                        <RadialGraph
                            dataForCanvas={dataForCanvas}
                            colorsArr={colorsArr}
                            total={total}
                            totalColor={totalColor}
                            holeSize={holeSize}
                            disabledCanvasColor={disabledCanvasColor}
                            tooltipButtonContent={<TooltipInfoContent title={title} data={graphTooltipData} />}
                            tooltipTitle={title}
                            circleWidth={circleWidth}
                            circleHeight={circleHeight}
                            data={data}
                            totalText={totalText}
                        />
                        <StatisticTable data={data} />
                    </ContentWrapper>
                ) : (
                    <Typography>
                        {intl.formatMessage({
                            id: 'Нет данных',
                        })}
                    </Typography>
                )}
            </>
        )
    }
)

export default AdditionalBaseItem
