const mobileSliderContainerStyles = (theme) => ({
    sliderContainer: {
        width: "100%",
        position: "relative",
        overflowX: "hidden",
        height: "calc(100vh - 64px)",
        overflow: "hidden",
        [theme.breakpoints.down("lg")]: {
            paddingBottom: "30px",
            height: "calc(100vh - 135px)"
        },

        [theme.breakpoints.down("1024")]: {
            paddingBottom: "14px"
        },
        [theme.breakpoints.down("744")]: {
            paddingBottom: "6px"
        },
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "0",
            height: "calc(100vh - 110px)"
        }
    },
    sliderButton: {
        height: "96px",
        width: "48px",
        background: "rgba(0,0,0,0.15)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "0 48px 48px 0",
        position: "absolute",
        left: 0,
        transform: "translate(0,50%)",
        bottom: "50%",
        "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
        "&:hover": {
            background: "rgba(0,0,0,0.3)"
        }
    },
    sliderButtonRight: {
        transform: "rotate(180deg) translate(0,-50%)",
        left: "calc(100% - 48px)"
    },
    slider: {
        display: "flex",
        flexWrap: "nowrap",
        position: "relative",
        left: 0,
        transition: "all 0.7s ease-in-out",
        width: "fit-content",
        [theme.breakpoints.down("lg")]: {
            height: "100%",
            overflow: "hidden",
            marginBottom: "63px"
        },
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            overflow: "hidden"
        }
    },
    sliderBar: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        zIndex: 1,
        left: "50%",
        transform: "translate(-50%, 0)",
        bottom: "8px",
        [theme.breakpoints.down("lg")]: {
            bottom: "-4px"
        },
        [theme.breakpoints.down("1024")]: {
            bottom: "-20px"
        },
        [theme.breakpoints.down("744")]: {
            bottom: "-29px"
        },
        [theme.breakpoints.down("sm")]: {
            bottom: "-35px"
        }
    },
    sliderBarItem: {
        width: "16px",
        height: "16px",
        background: "#DFDBD1",
        borderRadius: "16px",
        transition: "all 0.7s ease-in-out"
    },
    sliderBarItemSelected: {
        background: "rgba(0, 0, 0, 0.15)",
        width: 32
    }
})

export default mobileSliderContainerStyles
