import {makeAutoObservable} from "mobx"
import {getDashboardReasons, getRegionsStatistics} from "../../../services/ApiService"

class RegionsStore {
    constructor() {
        makeAutoObservable(this)
    }

    isLoading = true

    store = {
        1: {
            data: [],
            totalCount: null,
            dataForCanvas: {},
            totalColor: "#2067C1",
            colorsArr: ["#7091B7", "#8FBFF6", "#2067C1", "#2B2556", "#2B3155"]
        },

        2: {
            data: [],
            totalCount: null,
            dataForCanvas: {},
            totalColor: "#41B94D",
            colorsArr: ["#6EAA74", "#90DC97", "#41B94D", "#326937", "#48B56D"]
        }
    }

    getData = async () => {
        this.isLoading = true
        await getRegionsStatistics()
            .then((data) => {
                this.normalizeData(data)
            })
            .finally(() => (this.isLoading = false))
    }

    // getDataReasons = async (type = 2) => {
    //     this.isLoading = true
    //     await getDashboardReasons()
    //         .then((data) => {
    //             this.normalizeData(type, data)
    //         })
    //         .finally(() => (this.isLoading = false))
    // }

    calcPercents = (field, type, data) => {
        const result = data.map((item) => {
            const percentOfTotal = Math.floor((item[field] * 100) / this.store[type].totalCount)
            if (item[field] > 0 && percentOfTotal === 0) {
                item.percentOfTotal = "< 1"
            } else if (item[field] > 0 && percentOfTotal > 0) {
                item.percentOfTotal = percentOfTotal
            } else if (item[field] === 0) {
                item.percentOfTotal = 0
            }

            return item
        })

        return result
    }

    formationDataForCanvas = (field, data) => {
        const obj = {}
        data.forEach((item) => {
            if (item.name) {
                obj[item.name] = item[field]
            }
        })
        return obj
    }

    addColors = (type, data) => {
        const result = data.map((item, index) => {
            item.color = this.store[type].colorsArr[index]
            return item
        })
        return result
    }

    normalizeData = (data) => {
        const baseDataObj = {
            DNR: {
                name: "ДНР",
                installsCount: 0,
                createdCount: 0
            },
            LNR: {
                name: "ЛНР",
                installsCount: 0,
                createdCount: 0
            },
            ZO: {
                name: "ЗО",
                installsCount: 0,
                createdCount: 0
            },
            HO: {
                name: "ХО",
                installsCount: 0,
                createdCount: 0
            },
            Other: {
                name: "Другие",
                installsCount: 0,
                createdCount: 0
            }
        }

        data.stats.map((item) => {
            if (item.name?.toUpperCase().includes(baseDataObj.DNR.name)) {
                baseDataObj.DNR.installsCount += item.installsCount
                baseDataObj.DNR.createdCount += item.createdCount
            } else if (item.name?.toUpperCase().includes(baseDataObj.LNR.name)) {
                baseDataObj.LNR.installsCount += item.installsCount
                baseDataObj.LNR.createdCount += item.createdCount
            } else if (item.name?.toUpperCase().includes(baseDataObj.ZO.name)) {
                baseDataObj.ZO.installsCount += item.installsCount
                baseDataObj.ZO.createdCount += item.createdCount
            } else if (item.name?.toUpperCase().includes(baseDataObj.HO.name)) {
                baseDataObj.HO.installsCount += item.installsCount
                baseDataObj.HO.createdCount += item.createdCount
            } else {
                baseDataObj.Other.installsCount += item.installsCount
                baseDataObj.Other.createdCount += item.createdCount
            }
        })

        let dataArr = Object.values(baseDataObj)

        const prepareData = (type, field) => {
            this.store[type].totalCount = data[field]
            this.store[type].dataForCanvas = this.formationDataForCanvas(field, dataArr)
            dataArr = this.addColors(type, dataArr)
            dataArr = this.calcPercents(field, type, dataArr)
            this.store[type].data = dataArr.map((item) => {
                return {
                    name: item.name,
                    count: item[field],
                    color: item.color,
                    percentOfTotal: item.percentOfTotal
                }
            })
        }
        // подготовка данных стора в поле 1 и в поле 2
        prepareData(1, "createdCount")
        prepareData(2, "installsCount")
    }
}

export default RegionsStore
