import {getStyles} from "src/utils/utils/commonUtils"
import React from "react"
import mobileSliderContainerStyles from "src/views/AdditionalSummary/styles/mobileSliderContainerStyles"
import {Box} from "@mui/material"
import {observer} from "mobx-react"
import sliderIcon from "../../../../assets/images/slider/sliderScrollIcon.svg"
import {css} from "@emotion/css"

function SliderButtons({sliderStore, total, slider}) {
    const classes = getStyles(mobileSliderContainerStyles)
    const {changeItem, selectedItemIndex} = sliderStore
    const buttonsData = [
        {action: () => changeItem({slider}), disable: selectedItemIndex === 0},
        {action: () => changeItem({next: true, slider}), right: true, disable: selectedItemIndex === total - 1}
    ]
    return (
        <>
            {buttonsData.map(({action,disable}, index) => <Box
                key={"sliderButton" + index}
                    onClick={action}
                    sx={{...classes.sliderButton, ...(index ? classes.sliderButtonRight : {}),display: disable ? "none" : "flex"}}
                >
                    <img src={sliderIcon} alt={""}/>
            </Box>
            )}
        </>

    )
}

export default observer(SliderButtons)