import {Box, Typography} from "@mui/material"
import React from "react"
import {getStyles} from "../../../../utils/utils/commonUtils"
import AdditionalRadialRegionStyles from "../../styles/AdditionalRadialsStyles"

const ContentWrapper = ({children, title, maxWidth}) => {
    const classes = getStyles(AdditionalRadialRegionStyles)

    return (
        <Box sx={{...classes.itemWrapper, maxWidth}}>
            <Typography sx={classes.subtitle}>{title}</Typography>

            {children}
        </Box>
    )
}

export default ContentWrapper
