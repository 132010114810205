import {Paper} from "@mui/material"
import React from "react"

import StatisticTableItem from "./StatisticTableItem"
import AdditionalRadialsStyles from "../../../styles/AdditionalRadialsStyles"
import {getStyles} from "../../../../../utils/utils/commonUtils"

const StatisticTable = ({data}) => {
    const classes = getStyles(AdditionalRadialsStyles)
    return (
        <Paper sx={classes.table}>
            {data.map((item) => {
                return <StatisticTableItem key={item.name} item={item}/>
            })}
        </Paper>
    )
}

export default StatisticTable
