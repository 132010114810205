import PagedCollectionStore from 'src/utils/stores/PagedCollectionStore'
import { makeObservable, observable } from 'mobx'
import { getDashboardReasons } from '../../../services/ApiService'

class ReplacementGraphStore extends PagedCollectionStore {
    constructor() {
        super({ action: () => getDashboardReasons() })
        this.totalReplacementColor = '#2C5D64'
        this.totalInstallationsColor = '#D9D9D9'
        this.totalColor = '#41B94D'
        this.totalData = [
            {
                text: 'Выполненные установки',
                status: 6,
                color: this.totalColor,
                number: 0,
            },

            {
                text: 'Замены (Горынычи)',
                status: 'gorinich',
                color: this.totalReplacementColor,
                number: 0,
            },
        ]
        makeObservable(this, {
            totalData: observable,
        })
    }

    getTableData = (data, graphData) => {
        const replacementPercent = Math.round((data.totalReplacement / data.total) * 100)
        const installationsPercent = Math.round((1 - data.totalReplacement / data.total) * 100)
        const result = [
            {
                name: 'Всего',
                color: this.totalColor,
                percentOfTotal: 100,
                count: data.total,
            },
            {
                name: 'Замены',
                color: this.totalReplacementColor,
                percentOfTotal: replacementPercent === 0 && !!data.totalReplacement ? '<1' : replacementPercent,
                count: data.totalReplacement,
            },
        ]

        if (graphData) {
            result.push({
                name: 'Установки',
                color: this.totalInstallationsColor,
                percentOfTotal: installationsPercent,
                count: data.total - data.totalReplacement,
            })
        }

        return result
    }
    getGraphOptions = (data) => {
        return [
            {
                data: [data.total],
                colors: [this.totalColor],
            },
            {
                data: [data.totalReplacement, data.total - data.totalReplacement],
                colors: [this.totalReplacementColor, this.totalInstallationsColor],
            },
        ]
    }

    setData(data) {
        this.totalData.forEach((el) => (el.number = 0))
        this.totalData[0].number = data.totalCount
        this.totalData[1].number = data.replacesCount
        const addData = (acc, data, name) => {
            let obj = acc.find((el) => el && el.name.includes(name))
            if (!obj) {
                const namesIndex = {
                    ДНР: 0,
                    ЛНР: 1,
                    ЗО: 2,
                    ХО: 3,
                }
                obj = {
                    name: `Замены ${name}`,
                    total: 0,
                    totalReplacement: 0,
                }
                acc[namesIndex[name]] = obj
            }

            obj.total += data.totalCount
            obj.totalReplacement = data.replacesCount
        }

        const newData = data.stats.reduce((acc, item) => {
            switch (item.name) {
                case 'ДНР': {
                    addData(acc, item, 'ДНР')
                    break
                }
                case 'ЛНР': {
                    addData(acc, item, 'ЛНР')
                    break
                }
                case 'Херсонская область (ХО)': {
                    addData(acc, item, 'ХО')
                    break
                }
                case 'Запорожская область (ЗО)': {
                    addData(acc, item, 'ЗО')
                    break
                }
                default: {
                    break
                }
            }
            return acc
        }, [])

        super.setData(newData)
    }
}

export default ReplacementGraphStore
