import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Typography, Box, Tooltip } from '@mui/material'
import { getStyles } from '../../../../../../utils/utils/commonUtils'
import { MapWidgetStyles } from '../../MapWidgetStyles'
import { useIntl } from 'react-intl'
import copyDisCoor from '../../../../../../assets/images/displayCoordinates/copyDisCoor.svg'
import copyDisCoorClick from '../../../../../../assets/images/displayCoordinates/copyDisCoorClick.svg'
import cursorDisCoor from '../../../../../../assets/images/displayCoordinates/cursorDisCoor.svg'
import cursorDisCoorActive from '../../../../../../assets/images/displayCoordinates/cursorDisCoorActive.svg'

const CoordinatePicker = ({ setCursorActive, cursorActive, cursorCoordinates, savedСoordinates }) => {
    const intl = useIntl()
    const classes = getStyles(MapWidgetStyles)
    // const [cursorActive, setCursorActive] = useState(false)
    const inputRef = useRef(null)

    const handleCopy = () => {
        // Костыль для копирования без https
        if (inputRef.current && savedСoordinates && savedСoordinates[0]) {
            const tempTextarea = document.createElement('textarea')

            // Помещаем текст из p в textarea
            tempTextarea.value = inputRef.current.textContent

            // Добавляем временный textarea на страницу
            document.body.appendChild(tempTextarea)

            // Выделяем текст в textarea
            tempTextarea.select()

            // Копируем выделенный текст в буфер обмена
            document.execCommand('copy')

            // Удаляем временный textarea
            document.body.removeChild(tempTextarea)
        }
    }
    return (
        <Box sx={classes.containerDisCoor}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={classes.coordinatesTitleStyles}>
                    {intl.formatMessage({ id: 'Текущие координаты:' })}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '162px' }}>
                    <Tooltip title={'Курсор'} followCursor>
                        <img
                            onClick={() => setCursorActive(!cursorActive)}
                            style={{ width: '20px', height: '24px', margin: '0px 10px', cursor: 'pointer' }}
                            alt={`Cursor`}
                            src={cursorActive ? cursorDisCoorActive : cursorDisCoor}
                        />
                    </Tooltip>
                    <Typography sx={classes.coordinatesStyles}>
                        {`${
                            cursorCoordinates && typeof cursorCoordinates[1] === 'number'
                                ? cursorCoordinates[1].toFixed(4)
                                : '-'
                        } ${
                            cursorCoordinates && typeof cursorCoordinates[0] === 'number'
                                ? cursorCoordinates[0].toFixed(4)
                                : '-'
                        }`}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ color: '#A5A5A5', borderBottom: '1px solid', width: '367px' }}> </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={classes.coordinatesTitleStyles}>
                    {intl.formatMessage({ id: 'Сохраненные координаты:' })}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '162px' }}>
                    <Tooltip title={'Копировать'} followCursor>
                        <img
                            onClick={handleCopy}
                            style={{
                                width: '20px',
                                height: '24px',
                                margin: '0px 10px',
                                cursor: savedСoordinates && savedСoordinates[0] ? 'pointer' : 'default',
                            }}
                            alt={`Copy`}
                            src={savedСoordinates && savedСoordinates[0] ? copyDisCoor : copyDisCoorClick}
                        />
                    </Tooltip>
                    <Typography ref={inputRef} sx={classes.coordinatesStyles}>
                        {`${
                            savedСoordinates && typeof savedСoordinates[1] === 'number'
                                ? savedСoordinates[1].toFixed(4)
                                : '-'
                        } ${
                            savedСoordinates && typeof savedСoordinates[0] === 'number'
                                ? savedСoordinates[0].toFixed(4)
                                : '-'
                        }`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default observer(CoordinatePicker)
