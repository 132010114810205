import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import Paper from "@mui/material/Paper"
import {getStyles} from "src/utils/utils/commonUtils"
import replacementGraphStyles from "src/views/AdditionalSummary/styles/replacementGraphStyles"
import ReplacementGraphStore from "src/views/AdditionalSummary/stores/ReplacementGraphStore"
import PlateTitle from "src/views/AdditionalSummary/components/common/PlateTitle"
import {useIntl} from "react-intl"
import AdditionalHorizontalPlateItem from "src/views/AdditionalSummary/components/AdditionalHorizontalPlate/AdditionalHorizontaPlateItem"
import Box from "@mui/material/Box"
import GraphContent from "src/views/AdditionalSummary/ReplacementGraphWidget/components/GraphContent"
import LastChildSpace from "../../../components/customElements/LastChildSpace/LastChildSpace"

function ReplacementGraphWidget() {
    const classes = getStyles(replacementGraphStyles)
    const intl = useIntl()
    const [store] = useState(new ReplacementGraphStore())
    useEffect(() => {
        store.load()
    }, [])
    const title = intl.formatMessage({id: "Статистика замен оборудования по регионам"})
    const tooltipData = [
        "Виджет показывает соотношение выполненных установок и замен оборудования другого оператора по каждому региону.",
        "На диаграмме показаны общее количество установок по региону и относительная доля установок с заменой оборудования другого оператора (Горынычи).",
        "В правой части “дуги” диаграммы - общее количество установок по региону, взятое за 100%, в центре - количество установок с заменой оборудования другого оператора (Горынычи)."
    ]

    const reloadAction = () => store.reLoad()

    return (
        <Paper variant={"container"} sx={classes.paper}>
            <Box sx={classes.header}>
                <PlateTitle title={title} updateFunc={reloadAction} tooltipData={tooltipData} />
                <Box sx={classes.statusesContainer}>
                    {store.totalData.map((item) => {
                        return <AdditionalHorizontalPlateItem key={item.status} {...item} />
                    })}
                </Box>
            </Box>
            <Box sx={classes.graphsContainer}>
                {store.collection.map((el) => {
                    return (
                        <GraphContent
                            key={el.name}
                            data={el}
                            store={store}
                            iconTooltipData={{data: tooltipData, title}}
                        />
                    )
                })}
                <LastChildSpace />
            </Box>
        </Paper>
    )
}

export default observer(ReplacementGraphWidget)
