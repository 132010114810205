const AdditionalHorizontalPlateStyles = (theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 40px 24px 40px',
        background: theme.palette.background.grayLight,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '16px',
            position: 'relative',
            marginTop: '14px',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
            height: '100%',
        },
    },
    wrapper__content: {
        display: 'flex',
        gap: '24px',
        // alignItems: 'center',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        width: '100%',
        overflow: 'auto',
        [theme.breakpoints.down('lg')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '16px',
        },
        [theme.breakpoints.down('744')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            // gap: "8px"
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },

    item__wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '5px',
        padding: '8px 16px',
        width: '100%',
        borderRadius: '6px',
        background: '#FFF',
        minWidth: '200px',
        cursor: 'default',
        border: '1px solid #E0DFDF',
        [theme.breakpoints.down('744')]: {
            padding: '8px 5px',
            minWidth: '176px',
        },
        [theme.breakpoints.down('608')]: {
            padding: '8px 16px',
            minWidth: '200px',
        },
    },
    item__titleWrapper: {
        display: '-webkit-box',
        gap: '5px',
        // alignItems: 'center',
    },
    item__image: {
        width: '16px',
        height: '16px',
    },
    item__divider: {
        height: '66px',
        borderLeft: '1px solid #A5A5A5',
    },
    statNumbers: {
        fontFamily: "'PT Root UI'",
        fontSize: '2.4rem',
        lineHeight: 'normal',
        letterSpacing: 0,
    },
    statText: {
        fontFamily: "'PT Root UI'",
        fontSize: '0.8rem',
        lineHeight: 'normal',
        fontWeight: 400,
        letterSpacing: 0,
    },
    statSubtitle: {
        fontFamily: "'PT Root UI'",
        fontSize: '0.8rem',
        lineHeight: 'normal',
        fontWeight: 700,
        letterSpacing: '0.42px',
        textTransform: 'uppercase',
    },
    titleContainer: {
        minWidth: '226px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '45px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
            marginBottom: '16px',
            position: 'relative',
        },
    },
    valueContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    editBlock: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginTop: '3px',
    },
    editBlockButtons: { display: 'flex', flexDirection: 'column', gap: '8px', width: '24px' },
    editBlockBtn: { padding: 0, height: '14px', width: '14px' },
})

export default AdditionalHorizontalPlateStyles
