import PagedCollectionStore from "../../../../../utils/stores/PagedCollectionStore"
import {getConsolidation} from "../../../../../services/ApiService"
import {computed, makeObservable, observable, reaction} from "mobx"
import AdditionRegionsFiltersStore from "../../../stores/AdditionRegionsFiltersStore"

class LineRegionsGraphStore extends PagedCollectionStore {
    constructor() {
        super({action: getConsolidation})
        this.filterStore = new AdditionRegionsFiltersStore()
        this.isLoading = true
        makeObservable(this, {
            isLoading: observable,
            maxOrderTotal: computed
        })
    }

    // reactionFilterParams = reaction(
    //     () => this.filterStore.noEmptyFilters,
    //     (data) => {
    //         if(!this.isLoading){
    //             this.reLoad(this.filterStore.getValidateParams())
    //         }
    //     }
    // )
    reactionFilterParams = reaction(
        () => this.filterStore.data.territory,
        (data) => {
            if (!this.isLoading) {
                this.reLoad(this.filterStore.getValidateParams())
            }
        }
    )

    reLoad(params) {
        this.isLoading = true
        super.reLoad(params)
    }

    get maxOrderTotal() {
        return Math.max(...this.collection.map(el => el.total))
    }

    setData(data) {
        const filteredData = data.filter(el => [1, 6, 7].includes(el.statusId))
        let ordersTotal = 0
        const newData = filteredData.reduce((acc, {nameDistrict, statusId, total}) => {
            const localityUpdate = (locality) => {
                const order = locality.orders.find(el => el.statusId === statusId)
                if (order) {
                    order.total += total
                } else {
                    locality.orders.push({statusId, total})
                }
                locality.total += total
            }
            if (nameDistrict) {
                const locality = acc.find(el => el.nameDistrict.trim().toLowerCase() === nameDistrict.trim().toLowerCase())
                if (locality) {
                    localityUpdate(locality)
                    locality.orders.sort((a, b) => a.senderId - b.senderId)
                } else {
                    acc.push({
                        nameDistrict,
                        total,
                        orders: [{statusId, total}]
                    })
                }
                ordersTotal += total
            }
            // else {
            //     const locality = acc.find(el => el.nameDistrict === "Другие")
            //     if(locality){
            //         localityUpdate(locality)
            //     } else {
            //         acc.push({
            //             nameDistrict: "Другие",
            //             total,
            //             orders: [{statusId, total}]
            //         })
            //     }
            // }
            // ordersTotal += total
            return acc
        }, [])
        newData.sort((a, b) => {
            if (a.nameDistrict.trim().toLowerCase() < b.nameDistrict.trim().toLowerCase()) {
                return -1
            } else if (a.nameDistrict.trim().toLowerCase() === b.nameDistrict.trim().toLowerCase()) {
                return 0
            } else {
                return 1
            }

        })
        this.isLoading = false
        super.setData(newData)
    }
}

export default LineRegionsGraphStore