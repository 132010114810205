import Typography from '@mui/material/Typography'
import React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import questionMarkBlue from 'src/assets/images/common/questionMarkBlue.svg'
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth'
import { observer } from 'mobx-react'
import { checkMobileDevice } from 'src/utils/utils/commonUtils'

function TooltipInfoContent({ data, title, width, keywords = [] }) {
    const theme = useTheme()
    return (
        <Box
            px={{ xs: 6.5, sm: 2 }}
            pt={{ sm: 1 }}
            pb={{ sm: 2 }}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {isWidthUp('sm', width) && !checkMobileDevice() && (
                <Box sx={{ display: 'flex', margin: '0 0 16px 8px', width: '100%' }}>
                    <img src={questionMarkBlue} alt='' />
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: theme.palette.text.graySemidark,
                            marginLeft: '8px',
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
            )}

            {data && 
                data.map((text) => {
                    const getHighlightedText = (text) => {
                        if (typeof text !== 'string') {
                            return text
                        }
                        const regex = new RegExp(`(${keywords.join('|')})`, 'g')

                        const parts = text.split(regex)

                        return parts.map((part, index) =>
                            keywords.includes(part) ? <strong key={index}>{part}</strong> : part
                        )
                    }

                    return (
                        <Typography
                            variant={'body1'}
                            sx={{
                                marginBottom: '8px',
                                textAlign: 'center',
                                color: theme.palette.text.graySemidark,
                            }}
                            key={text}
                        >
                            {getHighlightedText(text)}
                        </Typography>
                    )
                })}
        </Box>
    )
}

export default withWidth()(observer(TooltipInfoContent))
