import React from 'react'
import AdditionStatStore from '../../stores/AdditionStatStore'
import { useEffect } from 'react'
import { useState } from 'react'
import { getStyles } from '../../../../utils/utils/commonUtils'
import AdditionalRadialsStyles from '../../styles/AdditionalRadialsStyles'
import { Box, Paper, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'
import AdditionStatFilers from '../common/AdditionRegionsFilters'
import PlateTitle from '../common/PlateTitle'
import ComponentProgressBackdrop from '../../../../components/ComponentProgressBackdrop/ComponentProgressBackdrop'
import ContentWrapper from 'src/views/AdditionalSummary/components/common/ContentWrapper'
import RadialGraph from 'src/views/AdditionalSummary/components/common/RadialGraph/RadialGraph'
import StatisticTable from 'src/views/AdditionalSummary/components/common/StatisticTable/StatisticTable'
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth'
import TooltipInfoContent from 'src/components/CustomTooltip/TooltipInfoContent'

function AdditionStatPlate({ width }) {
    const [store] = useState(() => new AdditionStatStore())
    const { data, dataDotation, dataForCanvas, disabledCanvasColor, totalColor, colorsArr, totalCount, filterStore } =
        store
    const intl = useIntl()
    const title = intl.formatMessage({
        id: 'Статистика заявок по типам оплаты',
    })
    const classes = getStyles(AdditionalRadialsStyles)
    const tooltipData = [
        'Виджет показывает соотношение заявок, выполненных установщиками, по типам оплат в разрезе регионов.',
        'В центре диаграммы - общее количество установленных заявок по региону, взятое за 100%',
        'Сектора диаграммы изображают показатели по отдельным типам оплат установленных заявок.',
        // "CRM - интерфейс системы (ЛК районных администраций, ЛК операторов колл-центра, ЛК менеджера Офиса, ЛК Дилера);",
        // "Сайт - Яндекс-форма, через которую пользователи оставляют заявки на сайте “Русский Мир ТВ” самостоятельно;",
        // "Файл - заявки прогружаются через файл, если невозможно их зарегистрировать иным способом (например, при отсутствии связи)."
    ]

    useEffect(() => {
        store.getData()
        return () => {
            store.reactionFilterParams()
            store.reactionTerritory()
        }
    }, [])

    return (
        <Paper sx={classes.wrapper}>
            <Box mb={2} width={1}>
                <PlateTitle title={title} updateFunc={store.getData} tooltipData={tooltipData} />
            </Box>
            {!isWidthUp('sm', width) ? <AdditionStatFilers filterStore={store.filterStore} /> : null}
            <Box sx={classes.contentWrapper} mb={{ sm: 7.5 }}>
                {
                    /* {store.data.length*/ true ? (
                        <>
                            <ContentWrapper
                                title={intl.formatMessage({ id: 'УСТАНОВЛЕННЫЕ ЗАЯВКИ' })}
                                maxWidth={'336px'}
                            >
                                <RadialGraph
                                    dataForCanvas={dataForCanvas}
                                    colorsArr={colorsArr}
                                    total={totalCount}
                                    totalColor={totalColor}
                                    holeSize={0.65}
                                    disabledCanvasColor={disabledCanvasColor}
                                    circleWidth={isWidthUp('sm', width) ? '272px' : '176px'}
                                    circleHeight={isWidthUp('sm', width) ? '272px' : '176px'}
                                    data={dataDotation}
                                    tooltipButtonContent={<TooltipInfoContent title={title} data={tooltipData} />}
                                    tooltipTitle={title}
                                    tooltipPlacement={'left-start'}
                                    titleSize={isWidthUp('sm', width) ? '36px' : '24px'}
                                    totalText={intl.formatMessage(
                                        {
                                            id: 'Всего заявок {filter}',
                                            defaultMessage: 'Всего заявок {filter}',
                                        },
                                        {
                                            filter:
                                                filterStore.data.territory === 'Все регионы'
                                                    ? ''
                                                    : filterStore.data.territory.includes('ЗО')
                                                    ? 'ЗО'
                                                    : filterStore.data.territory.includes('ХО')
                                                    ? 'ХО'
                                                    : filterStore.data.territory,
                                        }
                                    )}
                                />
                                {!isWidthUp('sm', width) ? <StatisticTable data={data} /> : null}
                            </ContentWrapper>
                            {isWidthUp('sm', width) ? (
                                <Box sx={classes.desktopFiltersContainer}>
                                    <AdditionStatFilers filterStore={store.filterStore} column={true} />
                                    <StatisticTable data={dataDotation} />
                                </Box>
                            ) : (
                                <Box sx={classes.desktopFiltersContainer}>
                                    <AdditionStatFilers filterStore={store.filterStore} column={true} />
                                    <StatisticTable data={dataDotation} />
                                </Box>
                            )}
                        </>
                    ) : (
                        <Typography>
                            {intl.formatMessage({
                                id: 'Нет данных',
                            })}
                        </Typography>
                    )
                }
            </Box>
            <ComponentProgressBackdrop isOpen={store.isLoading} />
        </Paper>
    )
}

export default withWidth()(observer(AdditionStatPlate))
