import React from 'react'
import Box from '@mui/material/Box'
import AdditionalHorizontalPlateItem from 'src/views/AdditionalSummary/components/AdditionalHorizontalPlate/AdditionalHorizontaPlateItem'
import { Typography, useTheme } from '@mui/material'

function ItemTooltipContent({ color, number, text, status, store, noIcon }) {
    const theme = useTheme()
    const data = {
        6: 'Счётчик показывает суммарное количество установок, которые Дилеры на текущий момент выполнили по всем регионам.',
        1: 'Счётчик показывает количество новых заявок на установку оборудования, поступивших от граждан через районные Администрации, Колл-центры и Форму сайта на настоящий момент.',
        2: 'Счётчик показывает количество заявок, которые после поступления в систему обработаны сотрудником офиса и в настоящий момент назначены на исполнителей - Дилеров.',
        4: 'Счётчик показывает количество заявок, которые Дилеры увидели в своих Личных кабинетах в системе, приняли к исполнению и в настоящий момент начали по ним работу.',
        102: 'Счётчик показывает суммарное количество установок, которые документально подтверждены и за них исполнителям начислено вознаграждение.',
        gorinich:
            'Счётчик показывает суммарное количество выполненных установок, при выполнении которых была произведена замена оборудования другого оператора вещания (Горынычи).',
        newGorinich: "Счётчик показывает количество новых заявок на замену оборудования другого оператора.",
        editableCount:
            'Счётчик показывает суммарное количество установок по оперативным данным (ОД), которые Дилеры на текущий момент выполнили по всем регионам.',
        countOD: 'Счётчик показывает суммарное количество установок по вводимым каждый день оперативным данным (ОД).',
        initialCount:
            'Счётчик показывает количество установок, которое было выполнено до начала ежедневного ввода оперативных данных (ОД) в систему. ',
    }

    return (
        <Box py={{ sm: 3 }} px={{ xs: 6.5, sm: 3 }}>
            <Box sx={{ margin: '0 auto 8px' }}>
                <AdditionalHorizontalPlateItem
                    color={color}
                    number={number}
                    text={text}
                    status={status}
                    tooltipItem={true}
                    store={store}
                    noIcon={noIcon}
                />
            </Box>
            <Typography
                variant={'body1'}
                sx={{ textAlign: 'center', color: theme.palette.text.graySemidark, marginTop: '8px' }}
            >
                {data[status]}
            </Typography>
        </Box>
    )
}

export default ItemTooltipContent
