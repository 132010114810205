const graphsCommonStyles = (theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, calc(50% - 8px))',
        gap: '16px',
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            gap: 0,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            gap: 0,
        },
    },
    mobileWidgetContainer: {
        [theme.breakpoints.down('lg')]: {
            width: 'calc(100vw - 64px)',
            height: '100%',
            // overflowY: "scroll",
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            scrollbarWidth: 'none',
        },
        [theme.breakpoints.down('1024')]: {
            width: 'calc(100vw - 32px)',
        },
        [theme.breakpoints.down('744')]: {
            width: 'calc(100vw - 16px)',
            height: '100%',
            // overflowY: "scroll",
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            scrollbarWidth: 'none',
        },
    },
    horizontalPlateContainer: {
        gridColumn: 'span 2',
    },
    lineRegionsWidgetContainer: {
        gridColumn: 1,
        [theme.breakpoints.down('1350')]: {
            gridColumn: 'span 2',
        },
    },
    radialGraphsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '15px',
        gridColumn: 2,
        alignContent: 'start',
        [theme.breakpoints.down('lg')]: {
            gridColumn: 'span 2',
        },
    },
})

export default graphsCommonStyles