import { Paper } from '@mui/material'
import React, { useState } from 'react'
import AdditionalHorizontalPlateItem from './AdditionalHorizontaPlateItem'
import { observer } from 'mobx-react'
import AdditionalHorizontalPlateStore from '../../stores/AdditionalHorizontalPlateStore'
import { useEffect } from 'react'
import { getStyles } from '../../../../utils/utils/commonUtils'
import AdditionalHorizontalPlateStyles from '../../styles/AdditionalHorizontalPlateStyles'
import PlateTitle from 'src/views/AdditionalSummary/components/common/PlateTitle'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import { gState } from '../../../../utils/controllers/GlobalStateController'
import LastChildSpace from '../../../../components/customElements/LastChildSpace/LastChildSpace'
import MapWidget from 'src/views/AdditionalSummary/components/MapWidget/MapWidget'
import MapWidgetStore from 'src/views/AdditionalSummary/components/MapWidget/MapWidgetStore'
import { isLooker } from '../../../../services/AccountService'
import withWidth, { isWidthDown, isWidthUp } from '@mui/material/Hidden/withWidth'

const AdditionalHorizontalPlate = observer(({ width }) => {
    const [totalOrdersStore] = useState(() => new AdditionalHorizontalPlateStore())
    // const [mapWidgetStore] = useState(() => new MapWidgetStore())
    const classes = getStyles(AdditionalHorizontalPlateStyles)
    const intl = useIntl()
    // переменная для обозначения роли для которой отображается изменяемый элемент
    const roleLabelAE = gState.roles.includes('LabelA') && gState.roles.includes('LabelE')

    const ordersCollection = [
        {
            text: 'Новые',
            status: 1,
            color: '#023A66',
        },
        {
            text: 'Новые, замены (Горынычи)',
            status: 'newGorinich',
            color: '#2C5D64',
        },
        {
            text: 'Назначены дилерам',
            status: 2,
            color: '#7091B7',
            divider: true,
        },
        {
            text: 'Приняты в работу',
            status: 4,
            color: '#7091B7',
        },
        {
            text: 'Выполненные установки',
            status: 6,
            color: '#41B94D',
        },
        // обьект для редактируемого компонента
        // {
        //     text: 'Установки (ОД)',
        //     status: 'editableCount',
        //     color: '#41B94D',
        //     individualStyles: {
        //         display: {
        //             xs: 'block',
        //             sm: 'none',
        //             md: 'none',
        //             lg: 'block',
        //         },
        //     },
        // },
        {
            text: 'Оплачено',
            status: 102,
            color: '#4CB575',
        },
        {
            text: 'Установлено, замены \n(Горынычи)',
            status: 'gorinich',
            color: '#2C5D64',
        },
    ]

    useEffect(() => {
        totalOrdersStore.getEditableCountTotal()
        totalOrdersStore.getOrders()

        // интервал обновления статусов
        const timeInterval =
            window.SAT.config.dashboardStatusesLoadInterval && window.SAT.config.dashboardStatusesLoadInterval >= 1000
                ? window.SAT.config.dashboardStatusesLoadInterval
                : 60000

        // обновление статусов
        const statusesInterval = setInterval(() => {
            totalOrdersStore.getOrders()
        }, timeInterval)

        // очистка интервала
        return () => {
            clearInterval(statusesInterval)
        }
    }, [])

    return (
        <Paper sx={{ ...classes.wrapper, overflow: 'auto', gap: '20px' }}>
            {isWidthUp('lg', width) && (
                <Box sx={{ ...classes.titleContainer, width: '100%', margin: 0 }}>
                    <PlateTitle
                        title={intl.formatMessage({ id: 'Статистика заявок по всем регионам' })}
                        updateFunc={totalOrdersStore.getOrders}
                        tooltipData={[
                            'Виджет показывает счётчики заявок и установок, которые на настоящий момент зарегистрированы в системе.',
                        ]}
                        propStyles={{ justifyContent: 'space-between' }}
                        btnStyles={{ position: 'absolute' }}
                        // optionsButtonContent={<>aasdasd</>}
                    />
                </Box>
            )}
            <Box sx={{ width: '100%' }}>
                {isWidthDown('lg', width) && <MapWidget />}
                <Box sx={{...classes.wrapper__content, gap:'16px'}}>
                    {ordersCollection.map((item) => {
                        return (
                            <AdditionalHorizontalPlateItem
                                key={item.status}
                                {...item}
                                number={totalOrdersStore.findOrder(item.status)}
                                store={totalOrdersStore}
                                roleLabelAE={roleLabelAE}
                            />
                        )
                    })}
                    <LastChildSpace />
                </Box>
            </Box>
        </Paper>
    )
})

export default withWidth()(AdditionalHorizontalPlate)
