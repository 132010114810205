import { Box, Paper } from '@mui/material'
import React from 'react'
import PlateTitle from '../../common/PlateTitle'
import MapWidget from '../MapWidget'
import { isLooker } from '../../../../../services/AccountService'
import { getStyles } from '../../../../../utils/utils/commonUtils'
import AdditionalHorizontalPlateStyles from '../../../styles/AdditionalHorizontalPlateStyles'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'

const MapPlate = () => {
    const classes = getStyles(AdditionalHorizontalPlateStyles)

    return (
        <Paper sx={{ ...classes.wrapper, overflow: 'auto', gap: '20px', padding: '16px', marginBottom: 0 }}>
            {/* <Box sx={{ ...classes.titleContainer, width: '100%', margin: 0 }}>
                <PlateTitle
                    title={intl.formatMessage({ id: 'Карта установок' })}
                    // tooltipData={[
                    //     'Виджет показывает счётчики заявок и установок, которые на настоящий момент зарегистрированы в системе. Информация обновляется 1 раз в минуту или по нажатию на соответствующую кнопку.',
                    // ]}
                    propStyles={{ justifyContent: 'space-between' }}
                    btnStyles={{ position: 'absolute' }}
                    optionsButtonContent={<>aasdasd</>}
                />
            </Box> */}
            <Box sx={{ width: '100%' }}>
                <MapWidget />
            </Box>
        </Paper>
    )
}

export default observer(MapPlate)
