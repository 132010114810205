import React, {useEffect, useRef, useState} from "react"
import Box from "@mui/material/Box"
import {observer} from "mobx-react"
import {getStyles} from "../../../../../../utils/utils/commonUtils"
import lineRegionsGraphStyles from "../styles/lineRegionsGraphStyles"
import Typography from "@mui/material/Typography"
import {css} from "@emotion/css"

function RegionsGraphLine({orders, maxValue}) {
    const containerRef = useRef(null)
    const classes = getStyles(lineRegionsGraphStyles)
    const [currentWidth, setCurrentWidth] = useState({
        1: 0,
        6: 0,
        7: 0
    })
    const colors = {
        1: "#A1C5E1",
        6: "rgba(28, 92, 58, 0.73)",
        7: "rgba(219, 70, 70, 0.56)"
    }

    const sortOrders = JSON.parse(JSON.stringify(orders)).sort((a, b) => a.statusId - b.statusId)

    const setWidth = (total, contentWidth) => {
        const calculatedWidth = Math.floor(contentWidth * total / maxValue)
        return `${(Number.isNaN(calculatedWidth) || calculatedWidth) < 30 ? (total.toString().length === 1 ? 10 :
            total.toString().length === 2 ? 18 : 26) : calculatedWidth}px`
    }
    useEffect(() => {
        const contentWidth = containerRef.current.clientWidth * 0.8
        const getTotal = (id) => {
            const order = sortOrders.find(el => el.statusId === id)
            return order ? order.total : 0
        }
        const widthOptions = {
            1: setWidth(getTotal(1), contentWidth),
            6: setWidth(getTotal(6), contentWidth),
            7: setWidth(getTotal(7), contentWidth),
        }
        setCurrentWidth(widthOptions)


    }, [containerRef, orders])


    return <Box className={css(classes.graphLineContainer)}
                ref={containerRef}>
        <Box sx={classes.graphLineDivider}>
            <Box sx={classes.vector}/>
        </Box>
        {sortOrders.map(({statusId, total}, i) => {
            return <Box key={i} sx={{
                background: colors[statusId],
                ...classes.graphLineItem, width: currentWidth[statusId]
            }}>
                <Typography variant={"body2"} sx={classes.graphLineItemText}>
                    {total}
                </Typography>
            </Box>
        })}
    </Box>
}

export default observer(RegionsGraphLine)