import React from 'react'
import { Popup } from 'react-map-gl'
import { Typography } from '@mui/material'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import { getStyles } from 'src/utils/utils/commonUtils'
import pointerPopupCss from './pointerTooltipCss'
import StatisticTableItem from 'src/views/AdditionalSummary/components/common/StatisticTable/StatisticTableItem'

function PointerTooltip({ store }) {
    const classes = getStyles(pointerPopupCss)
    const { hoverObject, getOrdersInstallInfo, getTerritoryName } = store
    const { coordinate, object } = hoverObject || {}

    return !!object ? (
        <Popup
            longitude={coordinate[0]}
            latitude={coordinate[1]}
            closeButton={false}
            anchor='bottom-left'
            offsetLeft={12}
            className={classes.root}
            dynamicPosition={true}
        >
            <Box sx={classes.titleBlock} px={2} pt={1}>
                <Typography variant={'h5'} sx={{ fontWeight: '600' }}>
                    {getTerritoryName(object)}
                </Typography>
            </Box>

            <Box pb={1} px={2}>
                {getOrdersInstallInfo(object).map((options) => (
                    <StatisticTableItem item={options} />
                ))}
            </Box>
        </Popup>
    ) : (
        <></>
    )
}

export default observer(PointerTooltip)
