const lineGraphFooter = (theme,props) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "fit-content",
        margin: "50px auto 0",
    ...(props.tooltip ? {[theme.breakpoints.up( "xs")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "0 auto",
        }} : {[theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "0 auto",
        }})
    },
    element: (i) => ({
        marginLeft: i && !props.tooltip ? "32px" : 0,
        marginTop: props.tooltip && "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginTop: !i ? props.tooltip ? 0 : "30px" : "16px",
        }
    }),
    tooltipButtonContainer: {
        position: "absolute",
        right: "-30px",
        transform: "translate(50%)",
        [theme.breakpoints.down("sm")]: {
            top: "50%"
        }
    }
})

export default lineGraphFooter