import React from "react"
import Box from "@mui/material/Box"
import {getComp} from "../../../../../../utils/utils/DI"
import Typography from "@mui/material/Typography"
import lineGraphFooter from "../styles/lineGraphFooter"
import {getStyles} from "../../../../../../utils/utils/commonUtils"
import TooltipButton from "src/components/CustomTooltip/TooltipButton"
import TooltipInfoContent from "src/components/CustomTooltip/TooltipInfoContent"

function LineGraphFooter({tooltip,title,tooltipData}) {
    const {getStatusName, getStatusImage} = getComp("StatusesStore")
    const classes = getStyles(lineGraphFooter, {tooltip})

    const data = [
        {
            name: getStatusName(1),
            icon: getStatusImage(1),
            color: "#A1C5E1"
        },
        {
            name: getStatusName(6),
            icon: getStatusImage(6),
            color: "#59886F"
        },
        {
            name: getStatusName(7),
            icon: getStatusImage(7),
            color: "#EA9797"
        },
    ]
    return (
        <Box sx={classes.container}>
            {
                data.map(({name, icon, color}, i) => {
                    return <Box sx={classes.element(i)}>
                        <Box sx={{background: color, width: "28px", height: "13px", marginRight: "8px"}}/>
                        <img alt={""} src={icon} style={{height: "16px", marginRight: "2px"}}/>
                        <Typography variant={"body1"}>
                            {name}
                        </Typography>
                    </Box>
                })
            }
            {
                !tooltip &&
                <Box sx={classes.tooltipButtonContainer}>
                    <TooltipButton content={<TooltipInfoContent title={title} data={tooltipData}/>} title={title} titleIcon={"blue"}/>
                </Box>
            }
        </Box>
    )
}

export default LineGraphFooter