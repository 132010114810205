import {css} from "@emotion/css";

const pointerPopupCss = (theme) => ({
    root: css({
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        maxWidth: 350,
        minWidth: 150,
        borderRadius: '12px 12px 12px 0',
        top: '-50px',
        left: '12px'
    }),
    titleBlock: css({
        width: '100%',
        backgroundColor: 'secondary.main',
        borderRadius: '12px 12px 0 0'
    }),
    // cross: css({
    //     cursor: 'pointer',
    //     background: `url(${cross}) no-repeat`,
    //     width: 24,
    //     height: 24,
    //     '&:hover':{
    //         background: `url(${crossHover}) no-repeat`,
    //     }
    // }),
    // InfoBlock: css({
    //     margin: '12px 0'
    // }),
    // listItem: css({
    //     display: 'flex',
    //     flexWrap: 'nowrap',
    //     alignItems: 'flex-start !important',
    //     padding: '0 0 5px 24px !important',
    //     '&:last-child':{
    //         paddingBottom: '0 !important'
    //     }
    // }),
    // conditionsBlock: css({
    //     display: 'flex',
    //     flexWrap: 'nowrap',
    //     alignItems: 'center',
    //     marginBottom: 12,
    //     cursor: 'pointer'
    // }),
    // listItemImg: css({
    //     marginTop: '-1px'
    // }),
})

export default pointerPopupCss