import { makeObservable, observable } from 'mobx';

class Piechart {
    constructor(options) {
        this.hoveredSliceColor = null;  // Инициализируем поле до makeObservable
        this.options = options;
        this.canvas = options.canvas;
        this.ctx = this.canvas.getContext('2d');
        this.colors = options.colors;
        this.sliceColors = [];
        this.shadowColor = null;
        this.totalSegments = 0;

        makeObservable(this, {
            hoveredSliceColor: observable,  // Теперь MobX знает о этом поле
        });

        // Привязываем методы к this
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);

        this.addEventListeners();
    }

    draw(isHovered = false, hoveredColor = null) {
        let totalValue = 0;
        let colorIndex = 0;

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.sliceColors = []; // Очищаем перед добавлением новых сегментов

        for (let categ in this.options.data) {
            totalValue += this.options.data[categ];
        }
        this.totalSegments = totalValue;

        let startAngle = 0;

        for (let categ in this.options.data) {
            const val = this.options.data[categ];
            const sliceAngle = (2 * Math.PI * val) / totalValue;
            const color = this.colors ? this.colors[colorIndex % this.colors.length] : 'red';

            const isCurrentHovered = isHovered && hoveredColor === color;
            this.drawPieSlice(
                this.ctx,
                this.canvas.width / 2,
                this.canvas.height / 2,
                Math.min(this.canvas.width / 2, this.canvas.height / 2),
                startAngle,
                startAngle + sliceAngle,
                color,
                isCurrentHovered,
                this.totalSegments
            );

            this.sliceColors.push({
                startAngle: startAngle,
                endAngle: startAngle + sliceAngle,
                color: color,
            });

            startAngle += sliceAngle;
            colorIndex++;
        }

        if (this.options.holeSize) {
            this.drawPieSlice(
                this.ctx,
                this.canvas.width / 2,
                this.canvas.height / 2,
                this.options.holeSize * Math.min(this.canvas.width / 2, this.canvas.height / 2),
                0,
                2 * Math.PI,
                '#fff',
                false
            );
        }
    }

    drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color, isHovered, totalSegments) {
        if (isHovered) {
            ctx.shadowColor = color;
            ctx.shadowBlur = 4;
        } else {
            ctx.shadowColor = 'transparent';
            ctx.shadowBlur = 0;
        }

        function checkValues(values) {
            let zeroCount = 0;
            let nonZeroCount = 0;

            for (const value of Object.values(values)) {
                if (value === 0) {
                    zeroCount++;
                } else {
                    nonZeroCount++;
                }
            }

            return zeroCount === Object.keys(values).length - 1 && nonZeroCount === 1;
        }

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius - 4, startAngle, endAngle);
        ctx.closePath();
        ctx.fill();

        ctx.strokeStyle = checkValues(this.options.data) ? 'rgb(177 177 177)' : '#fff';
        ctx.lineWidth = 1;

        if (totalSegments > 1) {
            ctx.stroke();
        }
    }

    handleMouseMove(e) {
        const rect = this.canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        let isHovered = false;
        let hoverColor = null;

        for (const slice of this.sliceColors) {
            if (isInsideSlice(x, y, this.canvas.width / 2, this.canvas.height / 2, Math.min(this.canvas.width / 2, this.canvas.height / 2), slice)) {
                hoverColor = slice.color;
                if (this.hoveredSliceColor !== slice.color) {
                    this.hoveredSliceColor = slice.color;
                    this.shadowColor = slice.color;
                    this.draw(true, slice.color);
                }
                isHovered = true;
                break;
            }
        }

        if (!isHovered && this.hoveredSliceColor !== null) {
            this.hoveredSliceColor = null;
            this.draw(false);
        }
    }

    handleMouseOut() {
        if (this.hoveredSliceColor !== null) {
            this.hoveredSliceColor = null;
            this.draw(false);
        }
    }

    addEventListeners() {
        this.canvas.addEventListener('mousemove', this.handleMouseMove);
        this.canvas.addEventListener('mouseout', this.handleMouseOut);
    }

    removeEventListeners() {
        this.canvas.removeEventListener('mousemove', this.handleMouseMove);
        this.canvas.removeEventListener('mouseout', this.handleMouseOut);
    }

    cleanup() {
        this.removeEventListeners();
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.sliceColors = [];
    }
}

function isInsideSlice(x, y, centerX, centerY, radius, slice) {
    const angle = Math.atan2(y - centerY, x - centerX);
    const normalizedAngle = (angle + 2 * Math.PI) % (2 * Math.PI);
    const distanceFromCenter = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);
    return (
        normalizedAngle >= slice.startAngle &&
        normalizedAngle <= slice.endAngle &&
        distanceFromCenter <= radius
    );
}

export default Piechart;
