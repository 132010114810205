import React from 'react'
import AdditionalHorizontalPlate from './components/AdditionalHorizontalPlate/AdditionalHorizontalPlate'
import { Box } from '@mui/material'
import AdditionalRadialRegionPlate from './components/AdditionalRadialRegionPlate/AdditionalRadialRegionPlate'
import AdditionStatPlate from './components/AdditionStatPlate/AdditionStatPlate'
import AdditionalLineRegionsWidget from './components/AdditionalLineRegionsWidget/AdditionalLineRegionsWidget'
import graphsCommonStyles from './styles/graphsCommonStyles'
import { getStyles } from '../../utils/utils/commonUtils'
import MobileSliderContainer from 'src/views/AdditionalSummary/components/MobileSliderContainer/MobileSliderContainer'
import withWidth, { isWidthDown, isWidthUp } from '@mui/material/Hidden/withWidth'
import ReplacementGraphWidget from 'src/views/AdditionalSummary/ReplacementGraphWidget/ReplacementGraphWidget'
import MapPlate from './components/MapWidget/components/MapPlate'

const AdditionalSummary = ({ width }) => {
    const classes = getStyles(graphsCommonStyles)
    return (
        <Box sx={classes.container}>
            <MobileSliderContainer>
                {isWidthDown('lg', width)? null:(
                    <Box sx={{ ...classes.horizontalPlateContainer, ...classes.mobileWidgetContainer }}>
                        <MapPlate />
                    </Box>
                )}
                <Box sx={{ ...classes.horizontalPlateContainer, ...classes.mobileWidgetContainer }}>
                    <AdditionalHorizontalPlate />
                </Box>

                <Box sx={{ ...classes.horizontalPlateContainer, ...classes.mobileWidgetContainer }}>
                    <ReplacementGraphWidget />
                </Box>

                <Box sx={{ ...classes.lineRegionsWidgetContainer, ...classes.mobileWidgetContainer }}>
                    <AdditionalLineRegionsWidget width={width}  />
                </Box>
                <Box
                    sx={{
                        ...classes.radialGraphsContainer,
                        ...classes.mobileWidgetContainer,
                    }}
                >
                    <AdditionalRadialRegionPlate />
                    {isWidthUp('sm', width) ? <AdditionStatPlate /> : null}
                </Box>
                {!isWidthUp('sm', width) ? (
                    <Box sx={{ ...classes.radialGraphsContainer, ...classes.mobileWidgetContainer }}>
                        <AdditionStatPlate />
                    </Box>
                ) : null}
            </MobileSliderContainer>
        </Box>
    )
}

export default withWidth()(AdditionalSummary)
