import React, { useState } from 'react'
import '../../styles/AdditionalHorizontalPlateStyles.js'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { getStyles } from '../../../../utils/utils/commonUtils.js'
import additionalHorizontalPlateItemStyles from '../../styles/AdditionalHorizontalPlateStyles.js'
import { observer } from 'mobx-react'
import TooltipButton from 'src/components/CustomTooltip/TooltipButton'
import ItemTooltipContent from 'src/views/AdditionalSummary/components/AdditionalHorizontalPlate/ItemTooltipContent'
import tableSettingsIcon from '../../../../assets/images/common/tableSettings.svg'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

import AdditionalHorizontalPlateStore from '../../stores/AdditionalHorizontalPlateStore.js'

const AdditionalHorizontalPlateItem = observer(
    ({
        color,
        number,
        text,
        belowText,
        status,
        tooltipItem,
        store,
        noIcon = false,
        onClickAction,
        individualStyles,
        dotationIcon
    }) => {
        const classes = getStyles(additionalHorizontalPlateItemStyles)
        const [totalOrdersStore] = useState(() => new AdditionalHorizontalPlateStore())

        return (
            <>
                <Box
                    sx={{
                        ...classes.item__wrapper,
                        cursor: onClickAction ? 'pointer' : 'default',
                        ...individualStyles,
                    }}
                    onClick={onClickAction && onClickAction}
                >
                    <Box>
                        <Box>
                            <Box sx={classes.item__titleWrapper}>
                                {noIcon ? (
                                    <img style={classes.item__image} src={noIcon} />
                                ) : (
                                    <img style={classes.item__image} src={totalOrdersStore.getImage(status)} />
                                )}
                                <Typography sx={{...classes.statText,whiteSpace: 'pre-line' }}>{text}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ ...classes.statText, textAlign: 'center' }}>{belowText}</Typography>
                    </Box>
                    <Box sx={classes.valueContainer}>
                        <img src={dotationIcon} alt='' />
                        <Typography
                            variant={status === 6 || status === 1 ? 'h5' : 'body1'}
                            sx={classes.statNumbers}
                            style={{ color }}
                        >
                            {number}
                        </Typography>
                        {tooltipItem ? null : (
                            <TooltipButton
                                content={
                                    <ItemTooltipContent
                                        status={status}
                                        number={number}
                                        color={color}
                                        text={text}
                                        store={store}
                                        noIcon={noIcon}
                                    />
                                }
                            />
                        )}
                    </Box>
                </Box>
            </>
        )
    }
)

export default AdditionalHorizontalPlateItem
