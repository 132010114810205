import Chart from "chart.js/auto"
import React, {useEffect, useLayoutEffect, useRef, useState} from "react"
import {observer} from "mobx-react"
import Box from "@mui/material/Box"
import {Typography, Zoom} from "@mui/material"
import replacementGraphStyles from "src/views/AdditionalSummary/styles/replacementGraphStyles"
import {getStyles} from "src/utils/utils/commonUtils"
import StatisticTableItem from "src/views/AdditionalSummary/components/common/StatisticTable/StatisticTableItem"
import Tooltip from "@mui/material/Tooltip"
import TooltipButton from "src/components/CustomTooltip/TooltipButton"
import TooltipInfoContent from "src/components/CustomTooltip/TooltipInfoContent"

function ReplacementGraph({data, tooltipData, totalColor, iconTooltipData}) {
    const classes = getStyles(replacementGraphStyles, {color: totalColor})
    const graphRef = useRef()
    const [graph, setGraph] = useState(null)
    const [tooltipElement, setTooltipElement] = useState(null)
    const renderGraph = () =>
        setGraph(
            new Chart(graphRef.current, {
                type: "doughnut",
                data: {
                    datasets: data.map((el) => ({
                        data: el.data,
                        backgroundColor: el.colors,
                        hoverBackgroundColor: el.colors,
                        borderWidth: 2,
                        cutout: 80
                    }))
                },
                options: {
                    onHover: (a, element, c) => {
                        const hoveredElementBackground = element[0]?.element.options?.backgroundColor
                        if (hoveredElementBackground) {
                            const data = tooltipData.find((el) => el.color === hoveredElementBackground)
                            if (!tooltipElement || tooltipElement.color !== hoveredElementBackground) {
                                setTooltipElement(data)
                                graphRef.current.style.cursor = "pointer"
                            }
                        } else {
                            setTooltipElement(null)
                            graphRef.current.style.cursor = "default"
                        }
                    },
                    rotation: -90,
                    circumference: 180,
                    legend: {
                        position: "center"
                    },
                    animation: {
                        animateRotate: true,
                        animateScale: false
                    },
                    plugins: {
                        tooltip: {
                            enabled: false
                        }
                    }
                }
            })
        )
    useLayoutEffect(() => {
        renderGraph()
    }, [])
    useEffect(() => {
        if (data && graph) {
            graph.destroy()
            renderGraph()
        }
    }, [data])

    return (
        <Box sx={classes.graphContainer}>
            <Box sx={classes.totalIcon}>
                <TooltipButton
                    content={<TooltipInfoContent title={iconTooltipData.title} data={iconTooltipData.data} />}
                    title={iconTooltipData.title}
                    titleIcon={"blue"}
                />
            </Box>
            <Box sx={classes.graphContentContainer}>
                <Typography sx={classes.graphTotalTitle}>Замены (Горынычи)</Typography>
                <Box sx={classes.graphTotalContainer}>
                    <Typography sx={classes.graphTotal}>{data[1].data[0]}</Typography>
                </Box>
            </Box>
            <Box sx={classes.graphCountsContainer}>
                <Typography variant={"body1"} sx={classes.graphCount}>
                    0
                </Typography>
                <Typography variant={"body1"} sx={classes.graphCount}>
                    {data[0].data[0]}
                </Typography>
            </Box>
            <Tooltip
                TransitionComponent={Zoom}
                followCursor
                placement="top"
                title={tooltipElement ? <StatisticTableItem item={tooltipElement} /> : null}
            >
                <canvas ref={graphRef}></canvas>
            </Tooltip>
        </Box>
    )
}

export default observer(ReplacementGraph)
