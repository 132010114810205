const filtersStyles = (theme) => ({
    container: {
        width: "100%",
        display: "grid",
        gap: "8px",
        margin: "16px 0",
        gridTemplateColumns: "repeat(2, 1fr)"
    },
    columnFilters:{
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: "16px"
    }
})

export default filtersStyles