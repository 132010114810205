import {
    getEditableCountId,
    getEditableCountTotal,
    getTotalOrders,
    postEditableCountCreate,
    postEditableCountUpdate,
} from '../../../services/ApiService'
import { getComp } from '../../../utils/utils/DI'
import replace from '../../../assets/images/common/replace.svg'
import newReplace from '../../../assets/images/common/Change_New_Orders.svg'
import checkInRound from '../../../assets/images/common/checkInRound.svg'
import { makeAutoObservable } from 'mobx'

class AdditionalHorizontalPlateStore {
    constructor() {
        this.statusesStore = getComp('StatusesStore')
        this.isLoading = false
        makeAutoObservable(this)
    }

    orders = []
    editableCountTotal = null

    getOrders = async () => {
        if (this.isLoading) {
            return
        }
        this.isLoading = true
        const data = await getTotalOrders({})
        this.isLoading = false
        const inProcessingStatuses = [12, 13, 14, 15, 16]
        const installedStatuses = [6, 9, 12, 13, 14, 15, 16]
       
        const newData = data.reduce((acc, item) => {
            if (installedStatuses.includes(item.status)) {
                if (item.reasonId === 3) {
                    const obj = acc.find((el) => el.status === 'gorinich')
                    if (obj) {
                        obj.count += item.count
                    } else {
                        acc.push({ status: 'gorinich', count: item.count })
                    }
                }

                if (inProcessingStatuses.includes(item.status)) {
                    const obj = acc.find((el) => el.status === 102)
                    if (obj) {
                        obj.count += item.count
                    } else {
                        acc.push({ status: 102, count: item.count })
                    }
                }

                const obj = acc.find((el) => el.status === 6)
                if (obj) {
                    obj.count += item.count
                } else {
                    acc.push({ status: 6, count: item.count })
                }
            } else if (item.status === 1 && item.reasonId === 3) {
                acc.push({ status: 'newGorinich', count: item.count })
            } else {
                const obj = acc.find((el) => el.status === item.status)
                if (obj) {
                    obj.count += item.count
                } else {
                    acc.push({ status: item.status, count: item.count })
                }
            }
            return acc
        }, [])
        
        this.orders = newData
    }

    findOrder = (param) => {
        // проверка на наличие специфического статуса элемента
        if (param === 'editableCount') {
            return this.editableCountTotal
        }
        const order = this.orders.find((item) => item.status === param)
        return order?.count || 0
    }

    getImage = (status) => {
        if (status === 'gorinich') {
            return replace
        }
        if (status === 'newGorinich') {
            return newReplace
        }
        if (status === 'editableCount') {
            return checkInRound
        }
        const image =
            status === 102 ? this.statusesStore.getReportStatusImage(102) : this.statusesStore.getStatusImage(status)
        return image
    }

    // получение count редактируемого компонента
    getEditableCountTotal = async () => {
        return await getEditableCountTotal().then((res) => {
            this.editableCountTotal = res.count + res.initialCount
        })
    }
}

export default AdditionalHorizontalPlateStore
