export const MapWidgetStyles = (theme) => ({
    root: {
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        // height: "50vh",
        height: '728px',
        background: '#fff',
        borderRadius: '6px',
        border: '1px solid #E0DFDF',

        [theme.breakpoints.down('lg')]: {
            height: '60vh',
            marginBottom: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '64vh',
        },
    },
    navigator: {
        position: 'absolute',
        zIndex: 1,
        right: '52px',
        top: '24px',
        [theme.breakpoints.down('sm')]: {
            top: '56px',
            right: '40px',
        },
    },

    layoutName: {
        position: 'absolute',
        zIndex: 1,
        left: '24px',
        top: '24px',
        height: '35px',
        background: 'rgba(246, 246, 246, 1)',
        padding: '8px',
        display: 'flex',
        gap: '4px',
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '6px',
        fontSize: '16px',
        textWrap: 'nowrap',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            top: '6px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '98%',
            justifyContent: 'center',
        },
    },

    displayingCoordinates: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 1,
        width: '406px',
        height: '80px',
        background: 'rgba(246, 246, 246, 1)',
        right: '16px',
        bottom: '18px',
        borderRadius: '4px',
    },
    containerDisCoor: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '8px 16px',
    },

    coordinatesStyles: {
        fontWeight: '500',
    },

    coordinatesTitleStyles: {
        fontSize: '16px',
        fontWeight: '400',
    },

    zoomLayoutName: {
        fontSize: '16px',
        fontWeight: '500',
    },

    zoomLayoutTitle: {},
    scaleWrapper: {
        position: 'absolute',
        top: '100px',
        right: '24px',
        height: '120px',
        width: '28px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 0',
        [theme.breakpoints.down('sm')]: {
            top: '120px',
            right: '20px',
        },
    },
})
