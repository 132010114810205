import React from "react"
import Tooltip from "@mui/material/Tooltip"
import questionMark from "src/assets/images/common/questionMark.svg"
import questionMarkBlue from "src/assets/images/common/questionMarkBlue.svg"
import Box from "@mui/material/Box"
import withWidth, {isWidthUp} from "@mui/material/Hidden/withWidth"
import {openModalFn} from "src/utils/controllers/ModalController"
import {checkMobileDevice} from "src/utils/utils/commonUtils"

function TooltipButton({content, width, iconColor, title, titleIcon, placement,buttonIcon}) {
    const openModal = () => {
        if (!isWidthUp("sm", width) || checkMobileDevice()) {
            openModalFn["custom-tooltip"]({content, title, iconColor: titleIcon})
        }
    }

    return content ? (
        <>
            <Tooltip
                title={content}
                disableTouchListener={true}
                disableHoverListener={!isWidthUp("sm", width)}
                placement={placement}
            >
                <Box
                    sx={{
                        width: "25px",
                        height: "25px",
                        background: iconColor === "blue" ? undefined : "#E0DFDF",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                            background: iconColor === "blue" ? undefined : "#c5c5c5",
                            cursor: "pointer"
                        }
                    }}
                    onClick={openModal}
                >
                    <img draggable="false" src={buttonIcon ? buttonIcon : iconColor === "blue" ? questionMarkBlue : questionMark} alt="" />
                </Box>
            </Tooltip>
        </>
    ) : null
}

export default withWidth()(TooltipButton)
