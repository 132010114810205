import React, {useEffect} from "react"
import Box from "@mui/material/Box"
import PrimaryCircularProgress from "../ProgressBackdrop/compoenents/PrimaryCircularProgress"

function ComponentProgressBackdrop({isOpen}) {
    useEffect(() => {
    }, [isOpen])
    return isOpen ?
        <Box sx={{
            background: "rgba(255,255,255,0.49)",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <PrimaryCircularProgress color="inherit"/>
        </Box> : null
}

export default ComponentProgressBackdrop