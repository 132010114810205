import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import Paper from "@mui/material/Paper"
import {useIntl} from "react-intl"
import LineRegionsGraph from "./LineRegionsGraph/LineRegionsGraph"
import LineRegionsGraphStore from "./stores/LineRegionsGraphStore"
import PlateTitle from "../common/PlateTitle"
import AdditionRegionsFilters from "../common/AdditionRegionsFilters"
import LineGraphFooter from "./LineRegionsGraph/components/LineGraphFooter"
import ComponentProgressBackdrop from "../../../../components/ComponentProgressBackdrop/ComponentProgressBackdrop"
import {useTheme} from "@mui/material"
import { isWidthDown } from "@mui/material/Hidden/withWidth"

function AdditionalLineRegionsWidget({width}) {
    const intl = useIntl()
    const theme = useTheme()
    const [store] = useState(() => new LineRegionsGraphStore())
    const title = intl.formatMessage({id: "Статистика заявок по регионам и округам"})
    const tooltipData = [
        "Виджет показывает распределение и соотношение заявок, установок и отказов в разрезе муниципальных и городских округов для каждого из регионов.",
        <LineGraphFooter tooltip={true} title={title} />,
        "Синим цветом показаны заявки, зарегистрированные и находящиеся в работе в настоящий момент.",
        "Зелёным цветом показаны обслуженные заявки, по которым установки были выполнены.",
        "Красным цветом показаны заявки, установки по которым выполнить невозможно."
    ]
    useEffect(() => {
        store.filterStore.data.territory = "ДНР"
        store.load(store.filterStore.getValidateParams())
        return () => store.reactionFilterParams()
    }, [])
    return (
        <Paper
            type={"container"}
            sx={{
                width: "100%",
                height: isWidthDown('lg', width)? "1086px": "100%",
                // minHeight: "1086px",
                position: "relative",
                paddingTop: "16px",
                [theme.breakpoints.down("sm")]: {
                    padding: "16px !important",
                    boxShadow: "none",
                    minHeight: "100%",
                    borderRadius: 0
                }
            }}
        >
            <PlateTitle
                title={title}
                updateFunc={() => store.reLoad(store.filterStore.getValidateParams())}
                tooltipData={tooltipData}
            />
            <AdditionRegionsFilters flag filterStore={store.filterStore} />
            <LineRegionsGraph store={store} />
            {store.collection.length ? <LineGraphFooter title={title} tooltipData={tooltipData} /> : null}
            <ComponentProgressBackdrop isOpen={store.isLoading} />
        </Paper>
    )
}

export default observer(AdditionalLineRegionsWidget)
